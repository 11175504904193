import { useMemo, useReducer, useState } from "react";
import FusePageSimple from "@fuse/core/FusePageSimple/FusePageSimple";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import TrombinoscopeHeader from "./TrombinoscopeHeader";
import UsersList from "./UsersList";
import { UserContext } from "../contexts";
import TrombinoscopeFilter from "./TrombinoscopeFilter";

const Root = styled(FusePageSimple)(({ theme }) => ({
  "& .FusePageSimple-header": {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.divider,
  },
  "& .FusePageSimple-toolbar": {},
  "& .FusePageSimple-content": {},
  "& .FusePageSimple-sidebarHeader": {},
  "& .FusePageSimple-sidebarContent": {},
}));

function FilterReducer(filter, action) {
  const tempFilter = { ...filter };
  tempFilter.page = 1;
  switch (action.type) {
    case "update":
      tempFilter[action.field].value = action.value;
      tempFilter[action.field].active = action.value !== "";
      break;
    case "disableAll":
      Object.keys(tempFilter).map((key) => {
        tempFilter[key].value = undefined;
        tempFilter[key].active = false;
      });
      break;
    case "disable":
      tempFilter[action.field].value = undefined;
      tempFilter[action.field].active = false;
      break;
    default:
      tempFilter.page = action.value;
      break;
  }

  return tempFilter;
}

export default function TrombinoscopePage() {
  const [data, setData] = useState([]);
  const [openFilters, setOpenFilters] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [filters, dispatchFilters] = useReducer(FilterReducer, {
    first_name: { active: false, value: undefined },
    last_name: { active: false, value: undefined },
    department: { active: false, value: undefined },
    email: { active: false, value: undefined },
    page: 1,
  });
  const value = useMemo(() => {
    return {
      filters,
      dispatchFilters,
      openFilters,
      setOpenFilters,
      data,
      setData,
      rowsPerPage,
      setRowsPerPage,
    };
  }, [filters, data]);

  return (
    <UserContext.Provider value={value}>
      <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
        {openFilters && <TrombinoscopeFilter />}
        <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
          <Root
            header={<TrombinoscopeHeader />}
            content={<UsersList />}
            scroll="content"
          />
        </Box>
      </Box>
    </UserContext.Provider>
  );
}
