import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import MyPaySlipTable from "./table/MyPaySlipTable";

export default function MyPaySlipBody() {
  return (
    <Box sx={{ width: "100%" }}>
      <MyPaySlipTable />
    </Box>
  );
}
