import axios from "axios";
import moment from "moment";
import "moment/locale/fr";

export default function getDepartments(companyId) {
  return axios.get(`api/department/${companyId}`);
}

export function getMonths() {
  const m = moment();
  const months = [];
  for (let i = 0; i < 12; i++) {
    const month = m.month(i).local("fr").format("MMMM");
    months.push({
      id: i + 1,
      name: month.charAt(0).toUpperCase() + month.slice(1),
    });
  }

  return months;
}
