import { useMemo, useReducer, useState } from "react";
import FusePageSimple from "@fuse/core/FusePageSimple/FusePageSimple";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import RulesOfProcedureHeader from "./RulesOfProcedureHeader";
import RulesOfProcedureBody from "./RulesOfProcedureBody";

const Root = styled(FusePageSimple)(({ theme }) => ({
  "& .FusePageSimple-header": {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.divider,
  },
  "& .FusePageSimple-toolbar": {},
  "& .FusePageSimple-content": {},
  "& .FusePageSimple-sidebarHeader": {},
  "& .FusePageSimple-sidebarContent": {},
}));

export default function RulesOfProcedurePage() {
  return (
    <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
      <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
        <Root
          header={<RulesOfProcedureHeader />}
          content={<RulesOfProcedureBody />}
          scroll="content"
        />
      </Box>
    </Box>
  );
}
