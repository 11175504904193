import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import history from "@history";
import { userAcceptTermsOfUse } from "../human_ressources/users/UserServices";
import { editTermsOfUse } from "../settings/terms_use/TermsOfUseServices";
import { useDispatch } from "react-redux";
import { setUser } from "app/store/userSlice";

function TermsOfUsePage() {
  const [privacy, setPrivacy] = useState();
  const [accept, setAccept] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    editTermsOfUse().then((res) => {
      setPrivacy(res.data);
    });
  }, []);

  function handleSubmitPrivacyPolicy() {
    if (accept) {
      const form = new FormData();
      form.append("accept", true);
      userAcceptTermsOfUse(form)
        .then((res) => {
          dispatch(setUser(res.data));
          setTimeout(() => history.push("/dashboard"), 0);
        })
        .catch((err) => {})
        .finally(() => {});
    } else {
      setError(true);
    }
  }

  function handleChangeAccept(e) {
    setAccept(e.target.checked);
    if (e.target.checked) {
      setError(false);
    }
  }

  return (
    <div className="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-center flex-1 min-w-0">
      <Paper className="h-full sm:h-auto md:flex md:items-start md:justify-end w-full sm:w-auto md:h-full md:w-1/2 py-8 px-16 sm:p-48 md:p-64 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none ltr:border-r-1 rtl:border-l-1">
        <div className="w-full mx-auto sm:mx-0">
          <Typography
            component="h1"
            className="font-bold text-2xl text-center mb-8"
          >
            Condition d'utilisation
          </Typography>
          <Paper
            sx={{
              height: "70vh",
              overflow: "auto",
              marginBottom: "1rem",
              padding: "1rem",
            }}
            elevation={2}
          >
            <div
              dangerouslySetInnerHTML={{ __html: privacy && privacy.text }}
            />
          </Paper>

          <Box className="flex justify-around">
            <Box className="flex flex-col">
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox onChange={handleChangeAccept} />}
                  label="J'accepte les conditions d'utilisation"
                />
              </FormGroup>
              {error && (
                <Typography className="text-red-500 text-sm">
                  Veuillez accepter les conditions d'utilisation
                </Typography>
              )}
            </Box>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSubmitPrivacyPolicy}
            >
              Continuer
            </Button>
          </Box>
        </div>
      </Paper>
    </div>
  );
}

export default TermsOfUsePage;
