import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useContext, useRef, useState } from "react";
import { UserContext } from "../contexts";

export default function TrombinoscopeFilter() {
  const [department, setDepartment] = useState("");
  const firstName = useRef(undefined);
  const lastName = useRef(undefined);
  const email = useRef(undefined);
  const { dispatchFilters, data, setPage } = useContext(UserContext);

  function submitFilter() {
    if (typeof firstName.current !== "object") {
      dispatchFilters({
        type: "update",
        field: "first_name",
        value: firstName.current,
      });
    }
    if (typeof lastName.current !== "object") {
      dispatchFilters({
        type: "update",
        field: "last_name",
        value: lastName.current,
      });
    }
    if (typeof email.current !== "object") {
      dispatchFilters({
        type: "update",
        field: "email",
        value: email.current,
      });
    }
    if (department !== undefined) {
      dispatchFilters({
        type: "update",
        field: "department",
        value: department,
      });
    }
  }

  return (
    <Box className="bg-white p-12 border-r-grey-950">
      <TextField
        autoComplete="off"
        className="mb-8"
        ref={lastName}
        label="Nom"
        variant="outlined"
        onChange={(e) => {
          lastName.current = e.target.value;
        }}
        fullWidth
      />
      <TextField
        autoComplete="off"
        className="mb-8"
        ref={firstName}
        label="Prénom"
        variant="outlined"
        onChange={(e) => {
          firstName.current = e.target.value;
        }}
        fullWidth
      />
      <TextField
        autoComplete="off"
        className="mb-8"
        ref={email}
        label="Email"
        variant="outlined"
        onChange={(e) => {
          email.current = e.target.value;
        }}
        fullWidth
      />
      <Box sx={{ minWidth: 120 }} className="mb-8">
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Département</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={department}
            label="Département"
            onChange={(e) => setDepartment(e.target.value)}
          >
            <MenuItem value="">--Choisissez un département--</MenuItem>;
            {data.department.map((dep) => {
              return <MenuItem value={dep.id}>{dep.name}</MenuItem>;
            })}
          </Select>
        </FormControl>
      </Box>
      <Button variant="contained" onClick={submitFilter}>
        Filter
      </Button>
    </Box>
  );
}
