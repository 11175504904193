import axios from "axios";

// eslint-disable-next-line import/prefer-default-export
export function userAcceptPrivacyPolicy(form) {
  return axios.post("api/users/accept-privacy-policy", form);
}

export function userAcceptTermsOfUse(form) {
  return axios.post("api/users/accept-terms-of-use", form);
}

export function downloadTemplate() {
  return axios.get(`api/company/download-template`, {
    responseType: "blob",
  });
}

export function disableUser(form) {
  return axios.post("api/user/disable", form);
}
