import axios from "axios";

export function submitMood(form) {
  return axios.post("api/mood/add", form);
}

export function getMood() {
  return axios.get("api/mood");
}

export function getMoodData() {
  return axios.get("api/mood/data");
}
