import DepartmentPage from "./DepartmentPage";

const DepartmentConfig = {
  settings: {
    layout: {
      config: {},
    },
  },

  routes: [
    {
      path: "settings/department",
      element: <DepartmentPage />,
    },
  ],
};

export default DepartmentConfig;
