import {
  Box,
  Checkbox,
  IconButton,
  TableBody,
  TableCell,
  TableRow,
  tableCellClasses,
} from "@mui/material";
import { MONTHS } from "app/configs/globals";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import fileDownload from "js-file-download";
import DeleteIcon from "@mui/icons-material/Delete";
import styled from "styled-components";
import { downloadPaySlip } from "../PaySlipServices";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#000",
    color: "#fff",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "1.2rem",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f5f5f5",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function handleDownloadPaySlip({ user_id, month, year }) {
  downloadPaySlip(user_id, month, year)
    .then((res) => {
      fileDownload(res.data, `${month}_${year}.pdf`);
    })
    .catch((res) => {
      alert(res.message);
    });
}

export default function PaySlipTableBody({
  rows,
  checkSelected,
  handleCheck,
  deleteSinglePaySlip,
}) {
  return (
    <TableBody>
      {rows.map((row) => (
        <StyledTableRow key={`${row.code}_${row.month}_${row.year}`}>
          <StyledTableCell scope="row" sx={{ fontSize: "0.75rem" }}>
            <Checkbox
              checked={checkSelected(row.id)}
              onClick={(event) => event.stopPropagation()}
              onChange={(event) => handleCheck(event, row.id)}
            />
          </StyledTableCell>
          <StyledTableCell scope="row">{row.code}</StyledTableCell>
          <StyledTableCell scope="row">{row.first_name}</StyledTableCell>
          <StyledTableCell scope="row">{row.last_name}</StyledTableCell>
          <StyledTableCell align="left">{row.dep_name}</StyledTableCell>
          <StyledTableCell align="left">{MONTHS[row.month]}</StyledTableCell>
          <StyledTableCell align="left">{row.year}</StyledTableCell>
          <StyledTableCell align="left">
            {row.created_at?.substring(0, 10)}
          </StyledTableCell>
          <StyledTableCell align="left">
            {row.date_download?.substring(0, 10)}
          </StyledTableCell>
          <StyledTableCell scope="row">
            <Box className="flex flex-row">
              <IconButton
                aria-label="download"
                onClick={() => handleDownloadPaySlip(row)}
              >
                <DownloadForOfflineIcon sx={{ fontSize: "1.5rem" }} />
              </IconButton>
              <IconButton
                aria-label="delete"
                onClick={() => deleteSinglePaySlip(row.id)}
              >
                <DeleteIcon sx={{ color: "red", fontSize: "1.5rem" }} />
              </IconButton>
            </Box>
          </StyledTableCell>
        </StyledTableRow>
      ))}
    </TableBody>
  );
}
