import Typography from "@mui/material/Typography";
import { motion } from "framer-motion";
import Button from "@mui/material/Button";
import { useContext } from "react";
import { Pagination, Stack } from "@mui/material";
import { UserContext } from "../contexts";

function TrombinoscopeHeader(props) {
  const userContext = useContext(UserContext);

  function handleChangePage(event, value) {
    userContext.dispatchFilters({ value });
  }

  return (
    <div className="p-24 sm:p-32 w-full border-b-1">
      <div className="flex flex-col items-center sm:items-start">
        <Typography
          component={motion.span}
          initial={{ x: -20 }}
          animate={{ x: 0, transition: { delay: 0.2 } }}
          delay={300}
          className="text-24 md:text-32 font-extrabold tracking-tight leading-none"
        >
          Trombinoscope
        </Typography>
        <Typography
          component={motion.span}
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
          delay={500}
          className="text-14 font-medium ml-2"
          color="text.secondary"
        >
          {`${userContext?.data?.users?.total} Utilisateurs`}
        </Typography>
        <Button
          onClick={() => userContext.setOpenFilters((prev) => !prev)}
          variant="contained"
          color="primary"
        >
          Filtres
        </Button>
        {userContext?.data?.users?.links?.length > 0 && (
          <Stack spacing={2}>
            <Pagination
              count={Math.ceil(
                userContext.data.users.total / userContext.rowsPerPage
              )}
              color="primary"
              onChange={handleChangePage}
              page={userContext.filters.page}
            />
          </Stack>
        )}
      </div>
    </div>
  );
}

export default TrombinoscopeHeader;
