import {
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Table,
  Typography,
} from "@mui/material";
import { useEffect, useReducer, useState } from "react";
import FuseLoading from "@fuse/core/FuseLoading";
import { motion } from "framer-motion";
import axios from "axios";
import fileDownload from "js-file-download";
import { useSelector } from "react-redux";
import moment from "moment";
import FuseScrollbars from "@fuse/core/FuseScrollbars";
import PaySlipTableHead from "./PaySlipLogTableHead";
import PaySlipTableBody from "./PaySlipLogTableBody";
import {
  deletePaySlips,
  downloadPaySlipsLogs,
  searchPaySlipsLog,
} from "../../PaySlipServices";
import ColoredButton from "app/shared-components/ColoredButton";

function FiltreReducer(filtre, action) {
  const temp = { ...filtre };
  if (action.type !== "page") {
    temp.page = 1;
  }
  if (action.type === "disableAll") {
    Object.entries(temp).forEach(([key, value]) => {
      temp[key] = undefined;
    });

    return temp;
  }
  temp[action.field] = action.value;

  return temp;
}

export default function PaySlipLogTable() {
  const [selectedStateMenu, setSelectedStateMenu] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [data, setData] = useState([]);
  const [order, setOrder] = useState({
    direction: "asc",
    id: null,
  });
  const [currentEtat, setCurrentEtat] = useState(undefined);
  const [currentDossierId, setCurrentDossierId] = useState(undefined);
  const [deleting, setDeleting] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);
  const [filtre, dispatchFiltre] = useReducer(FiltreReducer, {
    date: undefined,
    state: undefined,
    page: 1,
  });

  const [infoData, setInfoData] = useState({
    nextPage: undefined,
    prevPage: undefined,
    total: 0,
    currentPage: 1,
  });
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (order.id) {
      submitFilter();
    }
  }, [order]);

  const permissions = useSelector((state) => {
    return state.user.get_role.get_permissions;
  });
  const finalPermissions = [];
  for (let i = 0; i < permissions.length; i++) {
    finalPermissions.push(permissions[i].nom);
  }

  useEffect(() => {
    submitFilter();
  }, [filtre]);

  function handleRequestSort(event, property) {
    const id = property;
    let direction = "desc";

    if (order.id === property && order.direction === "desc") {
      direction = "asc";
    }
    setOrder({
      direction,
      id,
    });
  }

  function handleChangePage(event, value) {
    dispatchFiltre({
      type: "page",
      field: "page",
      value,
    });
  }

  function handleChangeRowsPerPage(event) {
    dispatchFiltre({
      field: "rowsPerPage",
      value: event.target.value,
    });
  }
  function getFilters() {
    let existFilter = false;

    Object.entries(filtre).forEach(([key, value]) => {
      if (filtre[key] !== undefined) existFilter = true;
    });

    return existFilter;
  }
  if (loading) {
    return <FuseLoading />;
  }

  if (data.length === 0) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 0.1 } }}
        className="flex flex-1 items-center justify-center h-full"
      >
        <Typography color="textSecondary" variant="h5">
          Aucune donnée à afficher!
        </Typography>
        {getFilters() && (
          <Button
            className="ml-10"
            variant="contained"
            onClick={desactiverFiltre}
          >
            Désactiver filtres
          </Button>
        )}
      </motion.div>
    );
  }

  function submitFilter() {
    const params = {};
    Object.assign(
      params,
      filtre.date && {
        ...params,
        date: moment(filtre.date).format("YYYY-MM-DD"),
      }
    );
    Object.assign(params, filtre.page && { ...params, page: filtre.page });
    Object.assign(
      params,
      filtre.state !== undefined && { ...params, state: filtre.state }
    );
    setLoading(true);
    searchPaySlipsLog(params)
      .then((res) => {
        setData(res.data.logs);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        alert(err);
      });
  }

  function disableSingleFiltre(single) {
    dispatchFiltre({
      field: single,
      value: undefined,
    });
    submitFilter();
  }
  function desactiverFiltre() {
    dispatchFiltre({
      type: "disableAll",
    });
    setRefreshTable(!refreshTable);
  }
  const deleteSinglePaySlip = (id) => {
    if (confirm("Etes-vous sûr de vouloir continuer?")) {
      setLoading(true);
      deletePaySlips([id])
        .then((res) => {
          submitFilter();
          alert("Fiche de paie supprimé avec succés");
        })
        .catch((err) => {
          alert(
            "Une erreur s'est produite lors de la suppression de la fiche de paie"
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const deleteSelectedPaySlips = () => {
    if (confirm("Etes-vous sûr de vouloir continuer?")) {
      setLoading(true);
      deletePaySlips(selected)
        .then((res) => {
          submitFilter();
          alert("Bulletins de paie supprimé avec succés");
        })
        .catch((err) => {})
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const checkSelected = (id) => {
    if (selected[filtre.page] && selected[filtre.page].indexOf(id) !== -1) {
      return true;
    }
    return false;
  };

  function exportLogs() {
    const params = {};
    Object.assign(
      params,
      filtre.date && {
        ...params,
        date: moment(filtre.date).format("YYYY-MM-DD"),
      }
    );
    Object.assign(
      params,
      filtre.state !== undefined && { ...params, state: filtre.state }
    );
    setLoading(true);
    downloadPaySlipsLogs(params)
      .then((res) => {
        fileDownload(res.data, `logs_fiches_paies.csv`);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  }

  if (data?.data.length === 0) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 0.1 } }}
        className="flex flex-1 items-center justify-center h-full"
      >
        <Typography color="textSecondary" variant="h5">
          Aucune donnée à afficher!
        </Typography>
        {getFilters() && (
          <Button
            className="ml-10"
            variant="contained"
            onClick={desactiverFiltre}
          >
            Désactiver filtres
          </Button>
        )}
      </motion.div>
    );
  }

  return (
    <FuseScrollbars className="grow overflow-x-auto">
      <ColoredButton callback={exportLogs} text="Exporter en CSV" />
      <Box className="mt-8">
        {filtre.date && (
          <Chip label="Date" onDelete={() => disableSingleFiltre("date")} />
        )}
        {filtre.state !== undefined && (
          <Chip label="Etat" onDelete={() => disableSingleFiltre("state")} />
        )}
      </Box>

      <Table
        stickyHeader
        className="min-w-xl"
        aria-labelledby="tableTitle"
        size="small"
      >
        <PaySlipTableHead
          selectedProductIds={selected[filtre.page]}
          order={order}
          onRequestSort={handleRequestSort}
          rowCount={data.data.length}
          deleteSelectedPaySlips={deleteSelectedPaySlips}
          setData={setData}
          setLoading={setLoading}
          filtre={filtre}
          dispatchFiltre={dispatchFiltre}
          submitFilter={submitFilter}
          permissions={finalPermissions}
          departments={departments}
        />
        <PaySlipTableBody
          rows={data.data}
          checkSelected={checkSelected}
          deleteSinglePaySlip={deleteSinglePaySlip}
        />
      </Table>
      <Stack spacing={2} className="flex flex-row items-center">
        <Pagination
          count={Math.ceil(data.total / (filtre.rowsPerPage || 20))}
          page={filtre.page}
          onChange={handleChangePage}
          color="secondary"
          showFirstButton
          showLastButton
        />
      </Stack>
    </FuseScrollbars>
  );
}
