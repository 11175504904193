import DashboardPage from "./DashboardPage";

const DashboardConfig = {
  settings: {
    layout: {
      config: {},
    },
  },

  routes: [
    {
      path: "dashboard",
      element: <DashboardPage />,
    },
  ],
};

export default DashboardConfig;
