import PaySlipPage from "./PaySlipPage";

const PaySlipConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: ["canReadPaySlips"],
  routes: [
    {
      path: "hr/payslips",
      element: <PaySlipPage />,
    },
  ],
};

export default PaySlipConfig;
