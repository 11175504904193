import {
  Alert,
  Box,
  CircularProgress,
  IconButton,
  Menu,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import MaxWidthDialog from "src/documentation/material-ui-components/MaxWidthDialog";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ColoredButton from "app/shared-components/ColoredButton";
import DepartmentUpdate from "./components/DepartmentUpdate";
import {
  deleteDepartment,
  findDepartment,
  getDepartments,
} from "./DepartmentServices";
import DepartmentAdd from "./components/DepartmentAdd";

export default function DepartmentBody() {
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState();
  const [openSnackBar, setOpenSnackBar] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [refreshDepartments, setRefreshDepartments] = useState(false);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("Nouveau département");
  const [selectedStateMenu, setSelectedStateMenu] = useState(null);
  const formRef = useRef({
    name: undefined,
  });
  const [filtre, setFilter] = useState({
    name: undefined,
  });

  useEffect(() => {
    setLoading(true);
    getDepartments()
      .then((res) => {
        setDepartments(res.data);
      })
      .catch((e) => {})
      .finally(() => setLoading(false));
  }, [refreshDepartments]);

  function handleSelectedDepartment(department) {
    setSelectedDepartment(department);
    setTitle("Modification Département");
    setOpenDialog(true);
  }

  function handleDeleteDepartment(department) {
    if (confirm("Etes-vous sûr de vouloir supprimer ce département?")) {
      deleteDepartment(department.id)
        .then((res) => {
          setOpenSnackBar(true);
          setRefreshDepartments((prev) => !prev);
        })
        .catch((err) => {});
    }
  }

  function handleNewDepartment() {
    setTitle("Nouveau département");
    setOpenDialog(true);
  }

  function openSelectedStateMenu(event) {
    setSelectedStateMenu(event.currentTarget);
  }

  function closeSelectedStateMenu() {
    setSelectedStateMenu(null);
  }

  function applyFilter() {
    setFilter((prev) => ({ ...prev, name: formRef.current.name }));
    setLoading(true);
    closeSelectedStateMenu();
    findDepartment(formRef.current.name)
      .then((res) => {
        setDepartments(res.data);
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const createMenu = () => {
    return (
      <Menu
        id="selectedStateMenu"
        anchorEl={selectedStateMenu}
        open={Boolean(selectedStateMenu)}
        onClose={closeSelectedStateMenu}
        keepMounted={false}
      >
        <div className="m-10 flex flex-col space-y-10">
          <TextField
            autoComplete="off"
            label="Nom"
            inputRef={(input) => {
              if (input) {
                setTimeout(() => {
                  input.focus();
                }, 10);
              }
            }}
            ref={formRef}
            defaultValue={filtre.name}
            onChange={(e) => {
              formRef.current.name = e.target.value;
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                applyFilter("code");
              }
            }}
          />
        </div>
        <div className="flex justify-center">
          <ColoredButton
            text="Filtrer"
            callback={() => {
              applyFilter("code");
            }}
          />
        </div>
      </Menu>
    );
  };

  return (
    <>
      {!loading && (
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Code</TableCell>
                <TableCell>
                  Nom
                  <IconButton onClick={(event) => openSelectedStateMenu(event)}>
                    <MoreHorizIcon />
                  </IconButton>
                  {selectedStateMenu && createMenu()}
                </TableCell>
                <TableCell>
                  Action
                  <IconButton onClick={handleNewDepartment}>
                    <AddCircleOutlineIcon sx={{ color: "green" }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {departments.map((d) => {
                return (
                  <TableRow key={d.code}>
                    <TableCell>{d.code}</TableCell>
                    <TableCell>{d.name}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleSelectedDepartment(d)}>
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      )}
      {loading && <CircularProgress />}
      <MaxWidthDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        title={title}
        content={
          title === "Nouveau département" ? (
            <DepartmentAdd setRefreshDepartements={setRefreshDepartments} />
          ) : (
            <DepartmentUpdate
              department={selectedDepartment}
              setRefreshDepartements={setRefreshDepartments}
            />
          )
        }
      />
    </>
  );
}

function CustomSnackBar({ openSnackBar, setOpenSnackBar }) {
  <Stack spacing={2} sx={{ width: "100%", height: "200px" }}>
    <Snackbar
      open={openSnackBar}
      autoHideDuration={6000}
      onClose={() => setOpenSnackBar(false)}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert
        onClose={() => setOpenSnackBar(false)}
        severity="success"
        sx={{ width: "100%" }}
      >
        Département supprimé avec succés!
      </Alert>
    </Snackbar>
  </Stack>;
}
