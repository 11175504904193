import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import * as yup from "yup";
import _ from "@lodash";
import { motion } from "framer-motion";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import jwtService from "../../auth/services/jwtService";

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  email: yup.string().email("veuillez entrez un email valide"),
});

function SignInPage() {
  const { control, formState, handleSubmit, setError, setValue, getValues } =
    useForm({
      mode: "onChange",
      defaultValues: { email: "" },
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [connectionError, setConnectionError] = useState();
  const [show, setShow] = useState({ email: true, password: false });

  useEffect(() => {
    setValue("email", "", {
      shouldDirty: true,
      shouldValidate: true,
    });
  }, [setValue]);

  function onSubmit({ email }) {
    setLoading(true);
    jwtService
      .checkEmail(email)
      .then((result) => {
        if (result.response === 1) {
          localStorage.setItem("username", email);
          window.location = result.sso;
        } else if (result.response === 0) {
          setShow({ ...show, email: false, password: true });
          setConnectionError(undefined);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.status === 403) {
          setConnectionError("Nom d'utilisateur incorrecte");
        } else {
          setConnectionError(
            "Une erreur s'est produite lors de l'authentification"
          );
        }
      });
  }

  function submitPassword() {
    setLoading(true);
    jwtService
      .signInWithEmailAndPassword(getValues().email, password)
      .then((res) => {})
      .catch((err) => {
        setLoading(false);
        if (err.response.status === 401) {
          setConnectionError("Mot de passe incorrect");
        } else {
          setConnectionError(
            "Une erreur s'est produite lors de l'authentification"
          );
        }
      });
  }

  return (
    <div className="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-1 min-w-0">
      <Paper className="h-full sm:h-auto md:flex md:items-center md:justify-end w-full sm:w-auto md:h-full md:w-1/2 py-8 px-16 sm:p-48 md:p-64 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none ltr:border-r-1 rtl:border-l-1">
        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
          <img
            className="w-128"
            src="assets/images/logo/logo_myvisio.png"
            alt="logo"
          />
          <form
            name="loginForm"
            noValidate
            className="flex flex-col justify-center w-full mt-32"
            onSubmit={handleSubmit(onSubmit)}
          >
            {show.email && (
              <>
                <Typography className="mb-32 text-2xl font-extrabold tracking-tight leading-tight">
                  Authentification
                </Typography>
                <motion.div
                  className="notification__tray"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0, x: "-12rem" }}
                >
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="mb-24"
                        label="Email"
                        autoFocus
                        type="email"
                        error={!!errors.email}
                        helperText={errors?.email?.message}
                        variant="outlined"
                        required
                        fullWidth
                      />
                    )}
                  />
                </motion.div>
                {!loading && (
                  <Button
                    variant="contained"
                    color="secondary"
                    className=" w-full mt-16"
                    aria-label="Sign in"
                    disabled={_.isEmpty(dirtyFields) || !isValid}
                    type="submit"
                    size="large"
                  >
                    Suivant
                  </Button>
                )}
              </>
            )}
            {show.password && (
              <>
                <Typography className="mb-32 text-2xl font-extrabold tracking-tight leading-tight">
                  Votre mot de passe
                </Typography>
                <motion.div
                  className="notification__tray"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0, x: "-12rem" }}
                >
                  <TextField
                    className="mb-24"
                    label="Password"
                    type="password"
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setConnectionError(undefined);
                    }}
                    variant="outlined"
                    required
                    fullWidth
                  />
                </motion.div>
                {!loading && (
                  <Button
                    variant="contained"
                    color="secondary"
                    className=" w-full mt-16"
                    aria-label="Sign in"
                    type="submit"
                    size="large"
                    disabled={password.length <= 4}
                    onClick={submitPassword}
                  >
                    Se connecter
                  </Button>
                )}
              </>
            )}

            <Box className="flex justify-center">
              {connectionError && connectionError.length > 0 && (
                <Typography className="text-red-500 font-bold m-4">
                  {connectionError}
                </Typography>
              )}
              {loading && <CircularProgress />}
            </Box>
          </form>
        </div>
      </Paper>

      <Box
        className="relative hidden md:flex flex-auto items-center justify-center h-full p-64 lg:px-112 overflow-hidden"
        sx={{ backgroundColor: "primary.main" }}
      >
        <svg
          className="absolute inset-0 pointer-events-none"
          viewBox="0 0 960 540"
          width="100%"
          height="100%"
          preserveAspectRatio="xMidYMax slice"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Box
            component="g"
            sx={{ color: "primary.light" }}
            className="opacity-20"
            fill="none"
            stroke="currentColor"
            strokeWidth="100"
          >
            <circle r="234" cx="196" cy="23" />
            <circle r="234" cx="790" cy="491" />
          </Box>
        </svg>
        <Box
          component="svg"
          className="absolute -top-64 -right-64 opacity-20"
          sx={{ color: "primary.light" }}
          viewBox="0 0 220 192"
          width="220px"
          height="192px"
          fill="none"
        >
          <defs>
            <pattern
              id="837c3e70-6c3a-44e6-8854-cc48c737b659"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect x="0" y="0" width="4" height="4" fill="currentColor" />
            </pattern>
          </defs>
          <rect
            width="220"
            height="192"
            fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
          />
        </Box>

        <div className="z-10 relative w-full max-w-2xl">
          <div className="text-7xl font-bold leading-none text-gray-100">
            <div>Bienvenue sur </div>
            <div>MyVisio</div>
          </div>
          <div className="mt-24 text-lg tracking-tight leading-6 text-gray-400">
            Votre portail de digitalisation RH
          </div>
        </div>
      </Box>
    </div>
  );
}

export default SignInPage;
