import MyPaySlipPage from "./MyPaySlipPage";

const MyPaySlipConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: ["canReadMyPaySlips"],
  routes: [
    {
      path: "mypayslips",
      element: <MyPaySlipPage />,
    },
  ],
};

export default MyPaySlipConfig;
