import { motion } from "framer-motion";
import List from "@mui/material/List";
import { useContext, useEffect } from "react";
import { Box, Pagination, Stack } from "@mui/material";
import UserListItem from "./UserListItem";
import { getTrombinoscope } from "./TrombinoscopeServices";
import { UserContext } from "../contexts";

export default function UsersList(props) {
  const { filters, setData, data, page } = useContext(UserContext);

  useEffect(() => {
    getTrombinoscope(filters).then((res) => {
      setData(res.data);
    });
  }, [filters, page]);

  return (
    <Box className="w-full">
      {data?.users?.data.length > 0 && (
        <motion.div
          className="w-full"
          initial={{ y: 20, opacity: 0 }}
          animate={{
            y: 0,
            opacity: 1,
            transition: { delay: 0.2 },
          }}
        >
          <List className="w-full m-0 p-0">
            {data?.users?.data.map((user) => {
              return <UserListItem user={user} />;
            })}
          </List>
        </motion.div>
      )}
    </Box>
  );
}
