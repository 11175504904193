import FuseUtils from "@fuse/utils";
import FuseLoading from "@fuse/core/FuseLoading";
import { Navigate } from "react-router-dom";
import settingsConfig from "app/configs/settingsConfig";
import SignInConfig from "../main/sign-in/SignInConfig";
import SignUpConfig from "../main/sign-up/SignUpConfig";
import SignOutConfig from "../main/sign-out/SignOutConfig";
import Error404Page from "../main/404/Error404Page";
import ExampleConfig from "../main/example/ExampleConfig";
import UserConfig from "../main/human_ressources/users/UserConfig";
import TrombinoscopeConfig from "../main/human_ressources/trombinoscope/TrombinoscopeConfig";
import PaySlipConfig from "../main/human_ressources/pay_slip/PaySlipConfig";
import PermissionConfig from "../main/settings/Permissions/PermissionConfig";
import MyPaySlipConfig from "../main/my_documents/MyPaySlipConfig";
import { default as SettingPrivacyPolicyConfig } from "../main/settings/privacy_policy/PrivacyPolicyConfig";
import { default as SettingTermsOfUseConfig } from "../main/settings/terms_use/TermsOfUseConfig";
import DepartmentConfig from "../main/settings/department/DepartmentConfig";
import RulesOfProcedureConfig from "../main/settings/rules_procedure/RulesOfProcedureConfig";
import DashboardConfig from "../main/dashboard/DashBoardConfig";
import MyProfileConfig from "../main/my_profile/MyProfileConfig";
import PrivacyPolicyConfig from "../main/privacy_policy/PrivacyPolicyConfig";
import TermsOfUseConfig from "../main/terms_of_use/TermsOfUseConfig";

const routeConfigs = [
  ExampleConfig,
  SignOutConfig,
  SignInConfig,
  SignUpConfig,
  UserConfig,
  TrombinoscopeConfig,
  PaySlipConfig,
  PermissionConfig,
  MyPaySlipConfig,
  SettingPrivacyPolicyConfig,
  SettingTermsOfUseConfig,
  DepartmentConfig,
  RulesOfProcedureConfig,
  DashboardConfig,
  MyProfileConfig,
  PrivacyPolicyConfig,
  TermsOfUseConfig,
];

const routes = [
  ...FuseUtils.generateRoutesFromConfigs(
    routeConfigs,
    settingsConfig.defaultAuth
  ),
  {
    path: "/",
    element: <Navigate to="/dashboard" />,
  },
  {
    path: "loading",
    element: <FuseLoading />,
  },
  {
    path: "404",
    element: <Error404Page />,
  },
  {
    path: "*",
    element: <Navigate to="404" />,
  },
];

export default routes;
