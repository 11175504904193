import RulesOfProcedurePage from "./RulesOfProcedurePage";

const RulesOfProcedureConfig = {
  settings: {
    layout: {
      config: {},
    },
  },

  routes: [
    {
      path: "settings/rules-of-procedure",
      element: <RulesOfProcedurePage />,
    },
  ],
};

export default RulesOfProcedureConfig;
