import axios from "axios";

export function getRulesOfPRocedure() {
  return axios.get("api/settings/rules-of-procedure");
}

export function addRulesOfProcedure(form) {
  return axios.post("api/settings/rules-of-procedure/add", form);
}

export function deleteRulesOfProcedure(form) {
  return axios.post("api/settings/rules-of-procedure/delete");
}

export function downloadRulesOfProcedure() {
  return axios.get("api/settings/rules-of-procedure/download", {
    responseType: "blob",
  });
}
