import axios from "axios";

export default function updatePermission(role, listPermissions) {
  const form = new FormData();
  form.append("role", role);
  form.append("permissions", listPermissions.join(","));

  return axios.post("api/permission/update", form);
}

export function updateRole(roleId, roleName) {
  const form = new FormData();
  form.append("id", roleId);
  form.append("name", roleName);

  return axios.post("api/role/update", form);
}

export function addRole(roleName) {
  const form = new FormData();
  form.append("name", roleName);

  return axios.post("api/role/add", form);
}
