import PrivacyPolicyPage from "./PrivacyPolicyPage";

const PrivacyPolicyConfig = {
  settings: {
    layout: {
      config: {},
    },
  },

  routes: [
    {
      path: "settings/privacy-policy",
      element: <PrivacyPolicyPage />,
    },
  ],
};

export default PrivacyPolicyConfig;
