const locale = {
  APPLICATIONS: "Applications",
  EXAMPLE: "Example",
  USERS: "Utilisateurs",
  SHOW_USERS: "Utilisateurs",
  TROMBINOSCOPE: "Trombinoscope",
  HUMAN_RESSOURCE: "Ressource Humaines",
  PAYSLIPS: "Diffusion Bulletins De Paies",
  SETTINGS: "Paramètres",
  MYPAYSLIPS: "Mes Bulletins De Paies",
  MYDOCUMENTS: "Mes Documents",
  DASHBOARD: "Accueil",
  PRIVACY_POLICY: "Politique De Confidentialité",
  Access: "Accés",
  TERMS_OF_USE: "Conditions D'utilisation",
  DEPARTMENT: "Départements",
  RULES_OF_PROCEDURE: "Règlement Intérieur",
};

export default locale;
