import FuseUtils from "@fuse/utils";
import AppContext from "app/AppContext";
import { Component } from "react";
import { matchRoutes } from "react-router-dom";
import withRouter from "@fuse/core/withRouter";
import history from "@history";
import {
  getSessionRedirectUrl,
  setSessionRedirectUrl,
  resetSessionRedirectUrl,
} from "@fuse/core/FuseAuthorization/sessionRedirectUrl";

class FuseAuthorization extends Component {
  constructor(props, context) {
    super(props);
    const { routes } = context;
    this.state = {
      accessGranted: true,
      routes,
    };
  }

  componentDidMount() {
    if (!this.state.accessGranted) {
      this.redirectRoute();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.accessGranted !== this.state.accessGranted;
  }

  componentDidUpdate() {
    if (!this.state.accessGranted) {
      this.redirectRoute();
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { location } = props;
    const userRole = props.user.role;
    const { pathname } = location;

    const matchedRoutes = matchRoutes(state.routes, pathname);

    const matched = matchedRoutes ? matchedRoutes[0] : false;
    const userHasPermission = FuseUtils.hasPermission(
      matched.route.auth,
      props.user
    );

    const userRedirectAcceptCharts = FuseUtils.userRedirectAcceptCharts(
      props.user
    );

    const ignoredPaths = [
      "/",
      "/callback",
      "/sign-in",
      "/check-email",
      "/sign-out",
      "/logout",
      "/404",
    ];

    if (matched && !userHasPermission && !ignoredPaths.includes(pathname)) {
      setSessionRedirectUrl("dashboard");
    } else if (userRedirectAcceptCharts) {
      setSessionRedirectUrl(userRedirectAcceptCharts);
    } else setSessionRedirectUrl("dashboard");

    console.log();

    return {
      accessGranted: matched
        ? userHasPermission &&
          (!userRedirectAcceptCharts || pathname === userRedirectAcceptCharts)
        : true,
    };
  }

  redirectRoute() {
    const userRole = this.props.user.role;
    const redirectUrl = getSessionRedirectUrl() || this.props.loginRedirectUrl;
    console.log(redirectUrl);
    /*
        User is guest
        Redirect to Login Page
        */
    if (!userRole || userRole.length === 0) {
      setTimeout(() => history.push("/sign-in"), 0);
    } else {
      /*
        User is member
        User must be on unAuthorized page or just logged in
        Redirect to dashboard or loginRedirectUrl
        */
      setTimeout(() => history.push(redirectUrl), 0);

      resetSessionRedirectUrl();
    }
  }

  render() {
    // console.info('Fuse Authorization rendered', this.state.accessGranted);
    return this.state.accessGranted ? this.props.children : null;
  }
}

FuseAuthorization.contextType = AppContext;

export default withRouter(FuseAuthorization);
