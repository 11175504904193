import axios from "axios";

export function updateDepartment(id, code, name) {
  const form = new FormData();
  form.append("id", id);
  form.append("code", code);
  form.append("name", name);

  return axios.post("api/settings/department/update", form);
}

export function addDepartment(code, name) {
  const form = new FormData();
  form.append("code", code);
  form.append("name", name);

  return axios.post("api/settings/department/add", form);
}

export function getDepartments() {
  return axios.get("api/settings/department/edit");
}

export function deleteDepartment(id) {
  const form = new FormData();
  form.append("id", id);

  return axios.post("api/settings/department/delete", form);
}

export function findDepartment(name) {
  return axios.get("api/department/search", {
    params: {
      name,
    },
  });
}
