import {
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Table,
  Typography,
} from "@mui/material";
import { useEffect, useReducer, useState } from "react";
import FuseLoading from "@fuse/core/FuseLoading";
import { motion } from "framer-motion";
import axios from "axios";
import fileDownload from "js-file-download";
import { useSelector } from "react-redux";
import { getDepartmentByCompany } from "src/app/main/departments/DepartmentServices";
import moment from "moment";
import FuseScrollbars from "@fuse/core/FuseScrollbars";
import PaySlipTableHead from "./MyPaySlipTableHead";
import PaySlipTableBody from "./MyPaySlipTableBody";

function FiltreReducer(filtre, action) {
  const temp = { ...filtre };
  if (action.type !== "page") {
    temp.page = 1;
  }
  if (action.type === "disableAll") {
    Object.entries(temp).forEach(([key, value]) => {
      temp[key] = undefined;
    });

    return temp;
  }
  temp[action.field] = action.value;

  return temp;
}

export default function MyPaySlipTable() {
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [data, setData] = useState([]);
  const [order, setOrder] = useState({
    direction: "asc",
    id: null,
  });
  const [refreshTable, setRefreshTable] = useState(false);
  const [filtre, dispatchFiltre] = useReducer(FiltreReducer, {
    month: undefined,
    year: undefined,
    page: 1,
    rowsPerPage: 12,
  });

  const user = useSelector((state) => state.user);
  useEffect(() => {
    getDepartmentByCompany(user.company_id).then((res) => {
      console.log(res.data);
      setDepartments(res.data);
    });
  }, []);

  useEffect(() => {
    if (order.id) {
      submitFilter();
    }
  }, [order]);

  const permissions = useSelector((state) => {
    return state.user.get_role.get_permissions;
  });
  const finalPermissions = [];
  for (let i = 0; i < permissions.length; i++) {
    finalPermissions.push(permissions[i].nom);
  }

  useEffect(() => {
    submitFilter();
  }, [filtre]);

  function handleRequestSort(event, property) {
    const id = property;
    let direction = "desc";

    if (order.id === property && order.direction === "desc") {
      direction = "asc";
    }
    setOrder({
      direction,
      id,
    });
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const temp = [...selected];
      temp[filtre.page] = data.data.map((n) => n.id);
      setSelected(temp);
      return;
    }
    const temp = [...selected];
    temp[filtre.page] = [];
    setSelected(temp);
  }

  function handleDeselect() {
    setSelected([]);
  }

  function handleChangePage(event, value) {
    dispatchFiltre({
      type: "page",
      field: "page",
      value,
    });
  }

  function getFilters() {
    let existFilter = false;

    Object.entries(filtre).forEach(([key, value]) => {
      if (filtre[key] !== undefined) existFilter = true;
    });

    return existFilter;
  }
  if (loading) {
    return <FuseLoading />;
  }

  if (data.length === 0) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 0.1 } }}
        className="flex flex-1 items-center justify-center h-full"
      >
        <Typography color="textSecondary" variant="h5">
          Aucun Décompte à afficher!
        </Typography>
        {getFilters() && (
          <Button
            className="ml-10"
            variant="contained"
            onClick={desactiverFiltre}
          >
            Désactiver filtres
          </Button>
        )}
      </motion.div>
    );
  }

  function submitFilter() {
    const params = {};
    Object.assign(params, filtre.month && { ...params, month: filtre.month });
    Object.assign(params, filtre.year && { ...params, year: filtre.year });
    Object.assign(params, filtre.page && { ...params, page: filtre.page });
    setLoading(true);
    axios
      .get("api/payslip/mypayslips", { params })
      .then((res) => {
        setData(res.data.payslips);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        alert(err);
      });
  }

  function disableSingleFiltre(single) {
    dispatchFiltre({
      field: single,
      value: undefined,
    });
    submitFilter();
  }
  function desactiverFiltre() {
    dispatchFiltre({
      type: "disableAll",
    });
    setRefreshTable(!refreshTable);
  }

  const checkSelected = (id) => {
    if (selected[filtre.page] && selected[filtre.page].indexOf(id) !== -1) {
      return true;
    }
    return false;
  };

  if (data?.data.length === 0) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 0.1 } }}
        className="flex flex-1 items-center justify-center h-full"
      >
        <Typography color="textSecondary" variant="h5">
          Aucune donnée à afficher!
        </Typography>
        {getFilters() && (
          <Button
            className="ml-10"
            variant="contained"
            onClick={desactiverFiltre}
          >
            Désactiver filtres
          </Button>
        )}
      </motion.div>
    );
  }

  return (
    <FuseScrollbars className="grow overflow-x-auto">
      {filtre.month && (
        <Chip label="Mois" onDelete={() => disableSingleFiltre("month")} />
      )}
      {filtre.year && (
        <Chip
          label={`Année = ${filtre.year}`}
          onDelete={() => disableSingleFiltre("year")}
        />
      )}

      <Table stickyHeader aria-labelledby="tableTitle" size="small">
        <PaySlipTableHead
          selectedProductIds={selected[filtre.page]}
          order={order}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={data.data.length}
          onMenuItemClick={handleDeselect}
          setData={setData}
          setLoading={setLoading}
          filtre={filtre}
          dispatchFiltre={dispatchFiltre}
          submitFilter={submitFilter}
          permissions={finalPermissions}
          departments={departments}
        />
        <PaySlipTableBody rows={data.data} />
      </Table>
      <Stack spacing={2} className="flex flex-row items-center">
        <Pagination
          count={Math.ceil(data.total / (filtre.rowsPerPage || 12))}
          page={filtre.page}
          onChange={handleChangePage}
          color="secondary"
          showFirstButton
          showLastButton
        />
      </Stack>
    </FuseScrollbars>
  );
}
