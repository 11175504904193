import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import { useSelector } from "react-redux";
import { selectUser } from "app/store/userSlice";
import { getMood, submitMood } from "./DashboardServices";
import ChartMood from "./ChartMood";
import _ from "lodash";

export default function DashboardBody() {
  const [loading, setLoading] = useState(false);
  const [mood, setMood] = useState(undefined);
  const [refreshMood, setRefreshMood] = useState(false);
  const user = useSelector(selectUser);
  const permissions = user.get_role.get_permissions;

  const finalPermissions = [];
  for (let i = 0; i < permissions.length; i++) {
    finalPermissions.push(permissions[i].name);
  }

  useEffect(() => {
    setLoading(true);
    getMood()
      .then((res) => {
        setMood(res.data > 0);
      })
      .finally(() => setLoading(false));
  }, [refreshMood]);

  useEffect(() => {}, []);

  function handleMood(chosenMood) {
    const form = new FormData();
    form.append("mood", chosenMood);
    setLoading(true);
    submitMood(form)
      .then((res) => {
        setRefreshMood((prev) => !prev);
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  }

  const styles = {
    verSatisfied: {
      color: "#6f7072",
      fontSize: "48px",
      "&:hover": {
        color: "green",
      },
    },
    satisfied: {
      color: "#6f7072",
      fontSize: "48px",
      "&:hover": {
        color: "orange",
      },
    },
    notSatisfied: {
      color: "#6f7072",
      fontSize: "48px",
      "&:hover": {
        color: "red",
      },
    },
  };

  return (
    <>
      {!loading && (
        <Box className="w-full flex flex-col">
          {mood === false && (
            <Box className="w-full flex flex-col justify-center items-center">
              <Typography className="m-8 font-bold text-xl" component="h2">
                Déclaration 100% anonyme
              </Typography>
              <Typography>
                Comment vous sentez-vous {user.first_name}?
              </Typography>
              <Box className="w-full flex flex-row justify-center gap-12">
                <Box className="flex flex-col justify-center items-center">
                  <IconButton onClick={() => handleMood(3)}>
                    <SentimentVerySatisfiedIcon sx={styles.verSatisfied} />
                  </IconButton>
                  <Typography>Trés Bien</Typography>
                </Box>
                <Box className="flex flex-col justify-center items-center">
                  <IconButton onClick={() => handleMood(2)}>
                    <SentimentSatisfiedIcon sx={styles.satisfied} />
                  </IconButton>
                  <Typography>Bien</Typography>
                </Box>
                <Box className="flex flex-col justify-center items-center">
                  <IconButton onClick={() => handleMood(1)}>
                    <SentimentVeryDissatisfiedIcon sx={styles.notSatisfied} />
                  </IconButton>
                  <Typography>Pas Bien</Typography>
                </Box>
              </Box>
            </Box>
          )}
          {mood && (
            <Box className="w-full flex flex-col justify-center items-center m-24">
              <Typography className="font-bold text-2xl">
                Merci pour votre participation. A la prochaine
                <ThumbUpAltIcon sx={{ color: "green" }} />
              </Typography>
            </Box>
          )}
          {mood &&
            _.intersection(finalPermissions, ["canReadHumor"]).length > 0 && (
              <ChartMood />
            )}
        </Box>
      )}
      {loading && (
        <Box
          className="w-full flex flex-col items-center justify-center"
          color="primary"
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
}
