import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import updatePermission, {
  addDepartment,
  updateDepartment,
} from "../DepartmentServices";
import ColoredButton from "app/shared-components/ColoredButton";

export default function DepartmentAdd({ setRefreshDepartements }) {
  const [loading, setLoading] = useState();
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const codeRef = useRef();
  const nameRef = useRef();

  function handleChangeDepartment() {
    setLoading(true);
    addDepartment(codeRef.current, nameRef.current)
      .then(() => {
        setOpenSnackBar(true);
        setRefreshDepartements((prev) => !prev);
      })
      .finally(() => setLoading(false));
  }

  return (
    <>
      <Box className="flex flex-col gap-8 m-8">
        <Box className="flex flex-col gap-8">
          <TextField
            ref={codeRef}
            id="outlined-basic"
            label="Code"
            variant="outlined"
            onChange={(e) => (codeRef.current = e.target.value)}
          />
          <TextField
            ref={nameRef}
            id="outlined-basic"
            label="Nom"
            variant="outlined"
            onChange={(e) => (nameRef.current = e.target.value)}
          />
        </Box>
        {!loading && (
          <ColoredButton text="Valider" callback={handleChangeDepartment} />
        )}
      </Box>

      {loading && <CircularProgress />}
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={openSnackBar}
          autoHideDuration={6000}
          onClose={() => setOpenSnackBar(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={() => setOpenSnackBar(false)}
            severity="success"
            sx={{ width: "100%" }}
          >
            Département ajouté avec succés!
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
}
