import axio from "axios";
import { useEffect } from "react";
import JwtService from "./services/jwtService";

export default function CheckSso() {
  const username = localStorage.getItem("username");
  useEffect(() => {
    JwtService.checkSso(username)
      .then((res) => {})
      .catch(() => {
        alert("Une erreur s'est produite lors de la vérification SSO");
      });
  }, []);
}
