import {
  Box,
  Checkbox,
  Icon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@mui/material";
import _ from "lodash";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getMonths } from "src/app/main/settings/SettingService";
import FilterMenu from "./Menus";

export default function PaySlipTableHead(props) {
  const user = useSelector((state) => state.user);
  const { departments } = props;

  const {
    selectedProductIds,
    setLoading,
    permissions,
    filtre,
    dispatchFiltre,
    submitFilter,
  } = props;

  const numSelected = selectedProductIds?.length
    ? selectedProductIds.length
    : 0;

  const rows = useMemo(
    () => [
      {
        id: "code",
        align: "left",
        disablePadding: false,
        label: "Matricule",
        sort: true,
        type: "text",
      },
      {
        id: "first_name",
        align: "left",
        disablePadding: false,
        label: "Prénom",
        sort: true,
        type: "text",
      },
      {
        id: "last_name",
        align: "left",
        disablePadding: false,
        label: "Nom",
        sort: true,
        type: "text",
      },
      {
        id: "department",
        align: "left",
        disablePadding: false,
        label: "Département",
        sort: true,
        type: "select",
        data: departments,
      },
      {
        id: "month",
        align: "center",
        disablePadding: false,
        label: "Mois",
        sort: true,
        type: "select",
        data: getMonths(),
      },
      {
        id: "year",
        align: "center",
        disablePadding: false,
        label: "Année",
        sort: true,
        type: "text",
      },
      {
        id: "created_at",
        align: "center",
        disablePadding: false,
        label: "Crée le",
        sort: true,
        type: "date",
      },
      {
        id: "date_download",
        align: "center",
        disablePadding: false,
        label: "Date téléchargement",
        sort: true,
        type: "date",
      },
      {
        id: "action",
        align: "center",
        disablePadding: false,
        label: "Action",
        sort: true,
      },
    ],
    [departments]
  );

  const [selectedProductsMenu, setSelectedProductsMenu] = useState(null);
  const [selectedFilterMenu, setSelectedFilterMenu] = useState(null);
  const [currentFilter, setCurrentFilter] = useState(null);

  function openSelectedProductsMenu(event) {
    setSelectedProductsMenu(event.currentTarget);
  }

  function closeSelectedProductsMenu() {
    setSelectedProductsMenu(null);
  }

  function openSelectedFilterMenu(event, id) {
    setCurrentFilter(id);
    setSelectedFilterMenu(event.currentTarget);
  }

  function closeSelectedFilterMenu() {
    setSelectedFilterMenu(null);
  }

  const createSortHandler = (property) => (event) => {
    props.onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow className="h-48 sm:h-64">
        <TableCell padding="none" className="w-40 md:w-64 text-center z-99">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < props.rowCount}
            checked={props.rowCount !== 0 && numSelected === props.rowCount}
            onChange={props.onSelectAllClick}
          />
          {numSelected > 0 && (
            <Box
              className="flex items-center justify-center absolute w-64 top-0 ltr:left-0 rtl:right-0 mx-56 h-64 z-10 border-b-1"
              sx={{
                background: (theme) => theme.palette.background.paper,
              }}
            >
              <IconButton
                aria-owns={selectedProductsMenu ? "selectedProductsMenu" : null}
                aria-haspopup="true"
                onClick={openSelectedProductsMenu}
                size="large"
              >
                <Icon>more_horiz</Icon>
              </IconButton>
              <Menu
                id="selectedProductsMenu"
                anchorEl={selectedProductsMenu}
                open={Boolean(selectedProductsMenu)}
                onClose={closeSelectedProductsMenu}
              >
                <MenuList>
                  <MenuItem
                    onClick={() => {
                      // dispatch(removeProducts(selectedProductIds));
                      // props.onMenuItemClick();
                      closeSelectedProductsMenu();
                      props.deleteSelectedPaySlips();
                    }}
                  >
                    <ListItemIcon
                      className="min-w-40"
                      onClick={() => props.deleteSelectedPaySlips()}
                    >
                      <Icon className="text-red text-20">delete</Icon>
                    </ListItemIcon>
                    <ListItemText primary="Supprimer Sélection" />
                  </MenuItem>
                  {_.intersection(permissions, ["canDeleteDecompte"]).length >
                    0 && (
                    <MenuItem
                      onClick={() => {
                        // dispatch(removeProducts(selectedProductIds));
                        // props.onMenuItemClick();
                        closeSelectedProductsMenu();
                        props.deleteSelectedDecomptes();
                      }}
                    >
                      <ListItemIcon
                        className="min-w-40"
                        onClick={() => props.deleteSelectedDecomptes()}
                      >
                        <Icon className="text-red text-20">delete</Icon>
                      </ListItemIcon>
                      <ListItemText primary="Supprimer Sélection" />
                    </MenuItem>
                  )}
                </MenuList>
              </Menu>
            </Box>
          )}
        </TableCell>
        {rows.map((row) => {
          return (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {(!row.permissions ||
                _.intersection(permissions, row.permissions).length > 0) && (
                <TableCell
                  className="p-4 md:p-16 text-xs"
                  key={row.id}
                  align={row.align}
                  padding={row.disablePadding ? "none" : "normal"}
                  sortDirection={
                    props.order.id === row.id ? props.order.direction : false
                  }
                >
                  {row.sort && (
                    <Tooltip
                      title="Sort"
                      placement={
                        row.align === "right" ? "bottom-end" : "bottom-start"
                      }
                      enterDelay={300}
                    >
                      <TableSortLabel
                        active={props.order.id === row.id}
                        direction={props.order.direction}
                        onClick={createSortHandler(row.id)}
                        className="font-semibold"
                      >
                        {row.label}
                      </TableSortLabel>
                    </Tooltip>
                  )}
                  {row.id !== "montant" &&
                    row.id !== "id" &&
                    row.id !== "action" && (
                      <>
                        <IconButton
                          aria-owns={
                            selectedFilterMenu ? "selectedProductsMenu" : null
                          }
                          aria-haspopup="true"
                          onClick={(event) => {
                            openSelectedFilterMenu(event, row);
                          }}
                          size="large"
                        >
                          <Icon>more_horiz</Icon>
                        </IconButton>
                        {currentFilter && (
                          <FilterMenu
                            selectedFilterMenu={selectedFilterMenu}
                            closeSelectedFilterMenu={closeSelectedFilterMenu}
                            currentFilter={currentFilter}
                            dispatchFiltre={dispatchFiltre}
                            filtre={filtre}
                            submitFilter={submitFilter}
                          />
                        )}
                      </>
                    )}
                </TableCell>
              )}
            </>
          );
        }, this)}
      </TableRow>
    </TableHead>
  );
}
