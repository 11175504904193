import {
  Box,
  Checkbox,
  Icon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@mui/material";
import _ from "lodash";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getMonths } from "src/app/main/settings/SettingService";
import FilterMenu from "../Menus";

export default function PaySlipLogTableHead(props) {
  const user = useSelector((state) => state.user);
  const { departments } = props;

  const {
    selectedProductIds,
    setLoading,
    permissions,
    filtre,
    dispatchFiltre,
    submitFilter,
  } = props;

  const numSelected = selectedProductIds?.length
    ? selectedProductIds.length
    : 0;

  const rows = [
    {
      id: "date",
      align: "left",
      disablePadding: false,
      label: "Date",
      sort: true,
      type: "date",
      filter: true,
    },
    {
      id: "state",
      align: "left",
      disablePadding: false,
      label: "Etat",
      sort: true,
      filter: true,
      type: "select",
      data: [
        { id: 0, name: "Echec" },
        { id: 1, name: "Délivré" },
      ],
    },
    {
      id: "message",
      align: "center",
      disablePadding: false,
      label: "Message",
      sort: true,
    },
  ];

  const [selectedFilterMenu, setSelectedFilterMenu] = useState(null);
  const [currentFilter, setCurrentFilter] = useState(null);

  function openSelectedFilterMenu(event, id) {
    setCurrentFilter(id);
    setSelectedFilterMenu(event.currentTarget);
  }

  function closeSelectedFilterMenu() {
    setSelectedFilterMenu(null);
  }

  const createSortHandler = (property) => (event) => {
    props.onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow className="h-48 sm:h-64">
        {rows.map((row) => {
          return (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {(!row.permissions ||
                _.intersection(permissions, row.permissions).length > 0) && (
                <TableCell
                  className="p-4 md:p-16 text-xs"
                  key={row.id}
                  align={row.align}
                  padding={row.disablePadding ? "none" : "normal"}
                  sortDirection={
                    props.order.id === row.id ? props.order.direction : false
                  }
                >
                  {row.sort && (
                    <Tooltip
                      title="Sort"
                      placement={
                        row.align === "right" ? "bottom-end" : "bottom-start"
                      }
                      enterDelay={300}
                    >
                      <TableSortLabel
                        active={props.order.id === row.id}
                        direction={props.order.direction}
                        onClick={createSortHandler(row.id)}
                        className="font-semibold"
                      >
                        {row.label}
                      </TableSortLabel>
                    </Tooltip>
                  )}
                  {row.id !== "id" && (
                    <>
                      {row.filter && (
                        <IconButton
                          aria-owns={
                            selectedFilterMenu ? "selectedProductsMenu" : null
                          }
                          aria-haspopup="true"
                          onClick={(event) => {
                            openSelectedFilterMenu(event, row);
                          }}
                          size="large"
                        >
                          <Icon>more_horiz</Icon>
                        </IconButton>
                      )}
                      {currentFilter && (
                        <FilterMenu
                          selectedFilterMenu={selectedFilterMenu}
                          closeSelectedFilterMenu={closeSelectedFilterMenu}
                          currentFilter={currentFilter}
                          dispatchFiltre={dispatchFiltre}
                          filtre={filtre}
                          submitFilter={submitFilter}
                        />
                      )}
                    </>
                  )}
                </TableCell>
              )}
            </>
          );
        }, this)}
      </TableRow>
    </TableHead>
  );
}
