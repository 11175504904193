import UserPage from "./UserPage";

const SignInConfig = {
  settings: {
    layout: {
      config: {},
    },
  },

  routes: [
    {
      path: "hr/users",
      element: <UserPage />,
    },
  ],
};

export default SignInConfig;
