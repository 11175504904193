import TermsOfUsePage from "./TermsOfUsePage";

const TermsOfUseConfig = {
  settings: {
    layout: {
      config: {},
    },
  },

  routes: [
    {
      path: "settings/terms-of-use",
      element: <TermsOfUsePage />,
    },
  ],
};

export default TermsOfUseConfig;
