import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import { useRef } from "react";

export default function FilterMenu({
  selectedFilterMenu,
  closeSelectedFilterMenu,
  currentFilter,
  dispatchFiltre,
  filtre,
  submitFilter,
}) {
  const textRef = useRef();
  const dateRef = useRef();

  function applyFilter(field, value) {
    dispatchFiltre({
      field,
      value,
    });
  }

  return (
    <Menu
      id="selectedFilterMenu"
      anchorEl={selectedFilterMenu}
      open={Boolean(selectedFilterMenu)}
      onClose={closeSelectedFilterMenu}
      keepMounted={false}
    >
      {currentFilter.type === "range_date" && (
        <div className="m-10 flex flex-col space-y-10">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              inputFormat="yyyy-MM-dd"
              label="Date début"
              value={filtre[currentFilter.id]}
              onChange={(newValue) => {
                dispatchFiltre({
                  field: currentFilter.id,
                  value: moment(newValue).format("YYYY-MM-DD"),
                });
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              inputFormat="yyyy-MM-dd"
              label="Date fin"
              value={filtre[currentFilter.id]}
              onChange={(newValue) => {
                dispatchFiltre({
                  type: "date_fin",
                  payload: { value: moment(newValue).format("YYYY-MM-DD") },
                });
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <Button
            variant="contained"
            onClick={() => {
              dispatchFiltre({
                type: "activeDate",
                payload: { value: true },
              });
              submitFilter();
            }}
          >
            Filter
          </Button>
        </div>
      )}
      {currentFilter.type === "date" && (
        <div className="m-10 flex flex-col space-y-10">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              format="yyyy-MM-dd"
              label="Date début"
              defaultValue={filtre[currentFilter.id]}
              onChange={(newValue) => {
                dateRef.current = newValue;
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <Button
            variant="contained"
            onClick={() => {
              dispatchFiltre({
                field: currentFilter.id,
                value: dateRef.current,
              });
            }}
          >
            Filter
          </Button>
        </div>
      )}
      {currentFilter.type === "text" && (
        <Box className="m-10 flex flex-col space-y-10">
          <TextField
            label={currentFilter.label}
            inputRef={(input) => {
              if (input) {
                setTimeout(() => {
                  input.focus();
                }, 10);
              }
            }}
            defaultValue={filtre[currentFilter.id]}
            onChange={(e) => {
              textRef.current =
                e.target.value.length > 0 ? e.target.value : undefined;
            }}
          />
          <Button
            variant="contained"
            onClick={() => {
              applyFilter(currentFilter.id, textRef.current);
            }}
          >
            Filter
          </Button>
        </Box>
      )}
      {currentFilter.type === "select" && (
        <div className="m-10 flex flex-col space-y-10">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              {currentFilter.label}
            </InputLabel>
            <Select
              label="Département"
              value={filtre[currentFilter.id]}
              onChange={(e) => {
                dispatchFiltre({
                  field: currentFilter.id,
                  value:
                    e.target.value.length > 0 || !isNaN(e.target.value)
                      ? e.target.value
                      : undefined,
                });
              }}
            >
              {currentFilter.data.map((d) => {
                return (
                  <MenuItem key={`${currentFilter.id}-${d.id}`} value={d.id}>
                    {d.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            onClick={() => {
              submitFilter();
            }}
          >
            Filter
          </Button>
        </div>
      )}
    </Menu>
  );
}
