import {
  Box,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import MaxWidthDialog from "src/documentation/material-ui-components/MaxWidthDialog";
import { getRoles } from "../../roles/rolesServices";
import PermissionList from "./components/PermissionList";
import { addRole, updateRole } from "./PermissionServices";

export default function PermissionBody() {
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState();
  const [addingRole, setAddingRole] = useState(false);
  const [updateSelectedRole, setUpdateSelectedRole] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [refreshRoles, setRefreshRoles] = useState(false);
  const [errorRole, setErrorRole] = useState();
  const [loading, setLoading] = useState(false);
  const title = "Liste des permissions";
  const roleRef = useRef();
  const newRoleRef = useRef();

  useEffect(() => {
    setLoading(true);
    getRoles()
      .then((res) => {
        setRoles(res.data);
      })
      .catch((e) => {
        console.log(
          "Une erreur s'est produite lors de la récupération des roles"
        );
      })
      .finally(() => setLoading(false));
  }, [refreshRoles]);

  function handleSelectedRole(role) {
    setSelectedRole(role);
    setOpenDialog(true);
  }

  function handleUpdateSelectedRole(roleId) {
    setUpdateSelectedRole(roleId);
  }

  function submitUpdateSelectedRole(roleId) {
    if (roleRef.current && typeof roleRef.current !== "object") {
      updateRole(roleId, roleRef.current)
        .then((res) => {
          res.status == 200
            ? console.log("Update role OK")
            : console.log("Non OK");
          setUpdateSelectedRole(undefined);
          setRefreshRoles((prev) => !prev);
        })
        .catch((err) => {
          setUpdateSelectedRole(undefined);
        });
    }
    setUpdateSelectedRole(undefined);
  }

  function handleChangeNewRole(e) {
    if (errorRole) setErrorRole(undefined);
    newRoleRef.current = e.target.value.length > 0 ? e.target.value : undefined;
  }

  function submitAddingRole() {
    if (newRoleRef.current && typeof newRoleRef.current !== "object") {
      setLoading(true);
      addRole(newRoleRef.current)
        .then((res) => {
          if (res.data === 406) {
            setErrorRole("Role déjà existant");
          } else if (res.data === 202) {
            setAddingRole(false);
            setRefreshRoles((prev) => !prev);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => setLoading(false));
    } else {
      setAddingRole(false);
    }
  }

  return (
    <>
      {!loading && (
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Box>
                    <Typography component="span"> Role </Typography>
                    <IconButton onClick={() => setAddingRole(true)}>
                      <AddCircleIcon />
                    </IconButton>
                  </Box>
                  {addingRole && (
                    <Box>
                      <Box>
                        <TextField
                          ref={newRoleRef}
                          onChange={handleChangeNewRole}
                        />
                        <IconButton>
                          <CheckIcon onClick={submitAddingRole} />
                        </IconButton>
                      </Box>
                      {errorRole && (
                        <Typography component="span" sx={{ color: "red" }}>
                          {errorRole}
                        </Typography>
                      )}
                    </Box>
                  )}
                </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {roles.map((r) => {
                return (
                  <TableRow>
                    <TableCell>
                      {(updateSelectedRole !== r.id || !updateSelectedRole) &&
                        r.name}
                      {updateSelectedRole && updateSelectedRole === r.id && (
                        <TextField
                          ref={roleRef}
                          onChange={(e) => (roleRef.current = e.target.value)}
                          defaultValue={r.name}
                        />
                      )}
                      <IconButton>
                        {!updateSelectedRole && (
                          <EditIcon
                            onClick={() => handleUpdateSelectedRole(r.id)}
                          />
                        )}
                        {updateSelectedRole && updateSelectedRole === r.id && (
                          <CheckIcon
                            onClick={() => submitUpdateSelectedRole(r.id)}
                          />
                        )}
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleSelectedRole(r)}>
                        <AdminPanelSettingsIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      )}
      {loading && <CircularProgress />}
      <MaxWidthDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        title={title}
        content={
          <PermissionList
            role={selectedRole}
            setRefreshRole={setRefreshRoles}
          />
        }
      />
    </>
  );
}
