import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Snackbar,
  SnackbarContent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { selectUser } from "app/store/userSlice";
import { useSelector } from "react-redux";
import changePassword from "./MyProfileServices";

export default function MyProfileBody() {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isSame, setIsSame] = useState(false);
  const user = useSelector(selectUser);
  console.log(user);
  useEffect(() => {
    if (password.length > 0 && confirmPassword.length > 0) {
      setIsSame(password === confirmPassword);
    }
  }, [password, confirmPassword]);

  function handleChangePassword() {
    const form = new FormData();
    form.append("password", password);
    setLoading(true);
    changePassword(form)
      .then((res) => {
        setIsSame(false);
        setPassword("");
        setConfirmPassword("");
        alert("Mot de passe changé avec succés");
      })
      .catch((err) => {
        alert("Une erreur s'est produite lors du changement de mot de passe");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const action = (
    <Button color="secondary" size="small">
      lorem ipsum dolorem
    </Button>
  );

  return (
    <>
      {!loading && (
        <Box className="w-full flex">
          <Box sx={{ flex: 1 }}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell className="font-bold">Matricule</TableCell>
                  <TableCell>{user.code}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="font-bold">Matricule Paie</TableCell>
                  <TableCell>{user.pay_code}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="font-bold">Nom</TableCell>
                  <TableCell>{user.last_name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="font-bold">Prénom</TableCell>
                  <TableCell>{user.first_name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="font-bold">Email</TableCell>
                  <TableCell>{user.email}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="font-bold">Fonction</TableCell>
                  <TableCell>{user.function}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="font-bold">Département</TableCell>
                  <TableCell>{user.get_department.name}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
          <Box sx={{ flex: 1 }}>
            <FormControl className="flex flex-col gap-12 m-8">
              <TextField
                label="Nouveau Mot de passe"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <TextField
                label="Confirmation du nouveau mot de passe"
                type="password"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              {password.length > 0 && confirmPassword.length > 0 && (
                <Typography
                  className={`${isSame ? "text-green-500" : "text-red-500"}`}
                >
                  {isSame
                    ? "Les mots de passe sont identiques"
                    : "Les mots de passe ne sont pas identique"}
                </Typography>
              )}
            </FormControl>
            <Button
              variant="contained"
              color="success"
              onClick={handleChangePassword}
              disabled={!isSame}
            >
              Changer Mot de passe
            </Button>
          </Box>
        </Box>
      )}
      {loading && (
        <Box
          className="w-full flex flex-col items-center justify-center"
          color="primary"
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
}
