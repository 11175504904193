import {
  Alert,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import fileDownload from "js-file-download";
import {
  addRulesOfProcedure,
  deleteRulesOfProcedure,
  downloadRulesOfProcedure,
  getRulesOfPRocedure,
} from "./RulesOfProcedureServices";
import ColoredButton from "app/shared-components/ColoredButton";

export default function RulesOfProcedureBody() {
  const [loading, setLoading] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(true);
  const [file, setFile] = useState();
  const [existingRules, setExistingRules] = useState(false);
  const [refreshRules, setRefreshRules] = useState(false);

  useEffect(() => {
    setLoading(true);
    getRulesOfPRocedure()
      .then((res) => {
        setExistingRules(res.data);
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  }, [refreshRules]);

  function handleDownload() {
    downloadRulesOfProcedure().then((res) => {
      fileDownload(res.data, `reglement_interieur.pdf`);
    });
  }

  function handleDelete() {
    if (confirm("Etes-vous sûr de vouloir supprimer le règlement intérieur?")) {
      setLoading(true);
      deleteRulesOfProcedure()
        .then((res) => {
          alert("Fichier supprimé avec succés!");
          setRefreshRules((prev) => !prev);
        })
        .finally(() => setLoading(false));
    }
  }

  function handleSubmit() {
    const form = new FormData();
    form.append("file", file);
    setLoading(true);
    addRulesOfProcedure(form)
      .then((res) => {
        if (res.data == 200) {
          setFile(undefined);
          setRefreshRules((prev) => !prev);
          alert("Le règlement intérieur a été ajouté avec succés!");
        }
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      {!loading && (
        <Box className="w-full flex flex-col items-center justify-center">
          {existingRules ? (
            <Box className="flex flex row items-center gap-4">
              <PictureAsPdfIcon sx={{ color: "red", fontSize: "3rem" }} />
              <Typography>Règlement Intérieur</Typography>
              <IconButton onClick={handleDownload}>
                <DownloadIcon sx={{ color: "red", fontSize: "3rem" }} />
              </IconButton>
              <IconButton onClick={handleDelete}>
                <DeleteIcon sx={{ color: "red", fontSize: "3rem" }} />
              </IconButton>
            </Box>
          ) : null}
          <Box className="flex flex-row items-center gap-8">
            <Button variant="contained" component="label">
              Ajouter ou remplacer un fichier
              <input
                type="file"
                hidden
                onChange={(e) => setFile(e.target.files[0])}
              />
            </Button>
            <Typography>
              {file ? file.name : "Aucun fichier séléctionné"}
            </Typography>
            <ColoredButton callback={handleSubmit} text="Valider" />
          </Box>
        </Box>
      )}
      {loading && (
        <Box
          className="w-full flex flex-col items-center justify-center"
          color="primary"
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
}
