import FuseScrollbars from "@fuse/core/FuseScrollbars";
import _ from "@lodash";
import Icon from "@mui/material/Icon";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { motion } from "framer-motion";
import { useEffect, useReducer, useState, useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import withRouter from "@fuse/core/withRouter";
import FuseLoading from "@fuse/core/FuseLoading";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import axios from "axios";
import {
  Button,
  Chip,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  CircularProgress,
  Box,
  FormControl,
  InputLabel,
  Select,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import MaxWidthDialog from "src/documentation/material-ui-components/MaxWidthDialog";
import UserTableHead from "./UserTableHead";
import getDepartments from "../../settings/SettingService";
import UpdateUserForm from "./UpdateUserForm";
import { getRoles } from "../../roles/rolesServices";
import { disableUser } from "./UserServices";

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

function FiltreReducer(filtre, action) {
  const temp = { ...filtre };
  switch (action.type) {
    case "page":
      temp.page = action.payload.value;
      break;
    case "rowsPerPage":
      temp.rowsPerPage = action.payload.value;
      break;
    case "code":
      temp.code.code = action.payload.value;
      break;
    case "activeCode":
      temp.code.filtered = action.payload.value;
      break;
    case "code-active":
      temp.code.code = action.payload.value;
      temp.code.filtered = true;
      break;
    case "last_name":
      temp.last_name.last_name = action.payload.value;
      break;
    case "activeLastName":
      temp.last_name.filtered = action.payload.value;
      break;
    case "last_name-active":
      temp.last_name.last_name = action.payload.value;
      temp.last_name.filtered = true;
      break;
    case "first_name":
      temp.first_name.first_name = action.payload.value;
      break;
    case "activeFirstName":
      temp.first_name.filtered = action.payload.value;
      break;
    case "first_name-active":
      temp.first_name.first_name = action.payload.value;
      temp.first_name.filtered = true;
      break;
    case "department":
      temp.department.department = action.payload.value;
      break;
    case "activeDepartment":
      temp.department.filtered = action.payload.value;
      break;
    case "department-active":
      temp.department.department = action.payload.value;
      temp.department.filtered = true;
      break;
    case "email":
      temp.email.email = action.payload.value;
      break;
    case "email-active":
      temp.email.email = action.payload.value;
      temp.email.filtered = true;
      break;
    case "activeEmail":
      temp.email.filtered = action.payload.value;
      break;

    case "disable":
      temp.code.filtered = false;
      temp.last_name.filtered = false;
      temp.first_name.filtered = false;
      temp.email.filtered = false;
      temp.office.filtered = false;
      break;
    case "disableSingleFiltre":
      temp[action.payload.value].filtered = false;
      break;
    default:
      break;
  }

  return temp;
}

function UserTable(props) {
  const {
    openDialog,
    setOpenDialog,
    currentContent,
    setCurrentContent,
    activeUsers,
  } = props;
  const [selectedStateMenu, setSelectedStateMenu] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState([]);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [order, setOrder] = useState({
    direction: "asc",
    id: null,
  });
  const [currentEtat, setCurrentEtat] = useState(undefined);
  const [currentDossierId, setCurrentDossierId] = useState(undefined);
  const [progress, setProgress] = useState(false);
  const [validating, setValidating] = useState(false);
  const [statusUsers, setStatusUsers] = useState(0);
  const [icon, setIcon] = useState(0);
  const [currentUser, setCurrentUser] = useState(null);
  const [currentSelectedUser, setCurrentSelectedUser] = useState(null);
  const [roles, setRoles] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [filtre, dispatchFiltre] = useReducer(FiltreReducer, {
    page: 1,
    rowsPerPage: 20,
    code: { code: "", filtered: false },
    last_name: { last_name: "", filtered: false },
    first_name: { first_name: "", filtered: false },
    department: { department: "", filtered: false },
    email: { email: "", filtered: false },
    office: { office: "", filtered: false },
  });
  const [client, setClient] = useState(-1);
  const [listClients, setListClients] = useState([]);
  const [infoData, setInfoData] = useState({
    nextPage: undefined,
    prevPage: undefined,
    total: 0,
    currentPage: 1,
  });
  const files = useRef([]);
  const permissions = useSelector(
    (state) => state.user.get_role.get_permissions
  );
  const user = useSelector((state) => state.user);
  const finalPermissions = [];
  for (let i = 0; i < permissions.length; i++) {
    finalPermissions.push(permissions[i].name);
  }

  function updateInfoData(nextPage, prevPage, total, currentPage) {
    const temp = { ...infoData };
    temp.nextPage = nextPage;
    temp.prevPage = prevPage;
    temp.total = total;
    temp.currentPage = currentPage;
    setInfoData(temp);
    console.log("TEMP==>", temp);
  }

  function handleRefreshTable() {
    submitFilter();
  }

  useEffect(() => {
    getDepartments(user.company_id).then((res) => {
      setDepartments(res.data);
    });

    getRoles().then((res) => {
      setRoles(res.data);
    });
  }, []);

  useEffect(() => {
    submitFilter();
  }, [filtre]);

  useMemo(() => {
    console.info("Progress", progress);
    if (progress >= 100) {
      setIcon(1);
    }
  }, [progress]);

  function handleRequestSort(event, property) {
    const id = property;
    let direction = "desc";

    if (order.id === property && order.direction === "desc") {
      direction = "asc";
    }

    setOrder({
      direction,
      id,
    });
  }

  function handleChangePage(event, value) {
    setPage(value);
    dispatchFiltre({
      type: "page",
      payload: {
        value,
      },
    });
  }

  function handleChangeRowsPerPage(value) {
    dispatchFiltre({
      type: "rowsPerPage",
      payload: {
        value,
      },
    });
  }
  function desactiverFiltre() {
    dispatchFiltre({
      type: "disable",
    });
    handleRefreshTable();
  }
  if (loading) {
    return <FuseLoading />;
  }

  if (data.length === 0) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 0.1 } }}
        className="flex flex-1 items-center justify-center h-full"
      >
        <Typography color="textSecondary" variant="h5">
          Aucun utilisateur à afficher!
        </Typography>
        {getFilters() && (
          <Button
            className="ml-10"
            variant="contained"
            onClick={desactiverFiltre}
          >
            Désactiver filtres
          </Button>
        )}
      </motion.div>
    );
  }

  const openUserUpdate = (selectedUser) => {
    closeSelectedStateMenu();
    console.log(selectedUser);
    setCurrentContent("updateUser");
    axios
      .get(`api/user/${selectedUser}`, {
        headers: {
          accept: "application/json",
        },
      })
      .then((res) => {
        setCurrentUser(res.data);
        setOpenDialog(true);
      })
      .catch((res) => {
        alert(res.message);
      });
  };

  const updateUser = (form) => {
    setValidating(true);
    axios
      .post(`api/user/update`, form)
      .then((res) => {
        setValidating(false);
        setOpenDialog(false);
        handleRefreshTable();
      })
      .catch((err) => {
        console.log(err);
        setValidating(false);
      });
  };

  function closeSelectedStateMenu() {
    setSelectedStateMenu(null);
  }

  function openSelectedStateMenu(event, current, etat) {
    setCurrentEtat(etat);
    setSelectedStateMenu(event.currentTarget);
    setCurrentSelectedUser(current);
  }

  function handleDisableUser() {
    const form = new FormData();
    form.append("id", currentSelectedUser);
    disableUser(form).then((res) => {
      if (res.data === 200) {
        closeSelectedStateMenu();
        handleRefreshTable();
        alert("Utilisateur désactivé avec succés");
      }
    });
  }

  const createMenu = (active) => {
    return (
      <Menu
        id="selectedStateMenu"
        anchorEl={selectedStateMenu}
        open={Boolean(selectedStateMenu)}
        onClose={closeSelectedStateMenu}
        keepMounted={false}
      >
        {currentEtat === "general" && (
          <MenuList>
            <MenuItem
              onClick={() => {
                openUserUpdate(currentSelectedUser);
              }}
            >
              <ListItemIcon className="min-w-40">
                <Icon className="text-green text-20">edit</Icon>
              </ListItemIcon>
              <ListItemText primary="Modifier" />
            </MenuItem>
            {active === 1 && (
              <MenuItem
                onClick={() => {
                  handleDisableUser(currentSelectedUser);
                }}
              >
                <ListItemIcon className="min-w-40">
                  <Icon className="text-red text-20">clear</Icon>
                </ListItemIcon>
                <ListItemText primary="Désactiver" />
              </MenuItem>
            )}
            {active === 0 && (
              <MenuItem
                onClick={() => {
                  enableUser(currentSelectedUser);
                }}
              >
                <ListItemIcon className="min-w-40">
                  <Icon className="text-green text-20">check_circle</Icon>
                </ListItemIcon>
                <ListItemText primary="Activer" />
              </MenuItem>
            )}
          </MenuList>
        )}
      </Menu>
    );
  };

  function getFilters() {
    let result = false;
    if (filtre.code.filtered) result = true;
    if (filtre.last_name.filtered) result = true;
    if (filtre.first_name.filtered) result = true;
    if (filtre.department.filtered) result = true;
    if (filtre.email.filtered) result = true;
    if (filtre.office.filtered) result = true;
    return result;
  }

  function getFormFilter() {
    const form = {};
    form.page = filtre.page;
    if (filtre.code.filtered) {
      form.code = filtre.code.code;
    }
    if (filtre.last_name.filtered) {
      form.last_name = filtre.last_name.last_name;
    }
    if (filtre.first_name.filtered) {
      form.first_name = filtre.first_name.first_name;
    }
    if (filtre.department.filtered) {
      form.department = filtre.department.department;
    }
    if (filtre.email.filtered) {
      form.email = filtre.email.email;
    }
    if (filtre.office.filtered) {
      form.office = filtre.office.office;
    }

    return form;
  }

  function submitFilter() {
    const form = getFormFilter();
    form.rowsPerPage = filtre.rowsPerPage;
    setLoading(true);
    axios
      .get("api/user/search", { params: form })
      .then((res) => {
        const result = res.data.users;
        setData(result.data);
        updateInfoData(
          result.next_page_url,
          result.prev_page_url,
          result.total,
          result.current_page
        );
        setLoading(false);
        // setPage(0);
      })
      .catch((err) => {
        setLoading(false);
        alert(err);
      });
  }

  function desactiverSingleFiltre(single) {
    dispatchFiltre({
      type: "disableSingleFiltre",
      payload: { value: single },
    });
  }

  const addFiles = (e) => {
    if (e.target.files.length > 0) {
      const justif = [];
      for (let i = 0; i < e.target.files.length; i++) {
        justif.push({ fichier: e.target.files[i] });
      }
      files.current = justif;
    }
  };

  const submitUsers = (onUploadProgress) => {
    if (client == -1) {
      alert("Veuillez choisir un client");
      return;
    }
    if (statusUsers == 0) {
      alert("Veuillez choisir le status des utilisateurs");
      return;
    }
    if (files.current.length === 0) {
      alert("Veuillez charger le fichier des utilisateurs");
      return;
    }
    setProgress(0);
    setValidating(true);
    const form = new FormData();
    form.append(`users`, files.current[0].fichier);
    form.append("client", client);
    form.append("status", statusUsers);
    axios
      .post("api/importUsers", form, {
        headers: {
          // Authorization: 'Bearer 93|6pJS41FLWgtC8P92IIIVZAJlacOfs9lobTFmcXxV',
        },
        onUploadProgress,
      })
      .then((res) => {
        setIcon(2);
        setProgress(0);
        setValidating(false);
        handleRefreshTable();
        alert(res.data.message);
        setOpenDialog(false);
      })
      .catch((err) => {
        setIcon(3);
      });
  };

  const ContentModal = () => {
    let result = null;
    switch (currentContent) {
      case "newUsers":
        result = (
          <div className="text-center mt-10">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Client</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={client}
                label="Age"
                onChange={(e) => setClient(e.target.value)}
              >
                <MenuItem value={-1}>--Cherchez un client--</MenuItem>
                {listClients &&
                  listClients.map(function (s) {
                    return (
                      <MenuItem value={s.id} key={`userstable_client_${s.id}`}>
                        {s.nom}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            <FormControl className="mt-10">
              <FormLabel>Status Utilisateurs</FormLabel>
              <RadioGroup
                onChange={(e) => {
                  setStatusUsers(e.target.value);
                }}
                aria-labelledby="demo-radio-buttons-group-label"
                name="status"
                row
              >
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="Entrants"
                />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label="Sortants"
                />
              </RadioGroup>
            </FormControl>
            <div className="mt-10 file-upload mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent">
              <input
                type="file"
                name="FileAttachment"
                id="FileAttachment"
                className="upload"
                onChange={(e) => addFiles(e)}
              />
            </div>

            {validating && (
              <>
                <div className="flex flex-row justify-center align items-center">
                  <CircularProgressWithLabel color="inherit" value={progress} />
                  <span>Chargement du fichier</span>
                </div>
                <div className="flex flex-row justify-center align items-center">
                  {icon === 0 && (
                    <CircularProgress
                      variant="determinate"
                      size={20}
                      value={75}
                    />
                  )}
                  {icon === 1 && <CircularProgress size={20} color="inherit" />}
                  {icon === 2 && <Icon color="success"> check_box</Icon>}
                  {icon === 3 && <Icon style={{ color: "red" }}> cancel</Icon>}
                  <span>Traitements du fichier</span>
                </div>
              </>
            )}

            {!validating && (
              <div className="mt-10">
                <Button
                  variant="contained"
                  color="success"
                  onClick={() =>
                    submitUsers((event) => {
                      setProgress(
                        Math.round((100 * event.loaded) / event.total)
                      );
                    })
                  }
                >
                  Valider
                </Button>
              </div>
            )}
          </div>
        );
        break;
      case "updateUser":
        result = (
          <UpdateUserForm
            currentUser={currentUser}
            finalPermissions={finalPermissions}
            roles={roles}
            departments={departments}
            updateUser={updateUser}
          />
        );

        break;
      default:
        result = null;
        break;
    }
    return result;
  };
  return (
    <div className="w-full flex flex-col">
      <FuseScrollbars className="grow overflow-x-auto">
        {filtre && filtre.code && filtre.code.filtered && (
          <Chip label="Code" onDelete={() => desactiverSingleFiltre("code")} />
        )}
        {filtre && filtre.last_name && filtre.last_name.filtered && (
          <Chip
            label="Nom"
            onDelete={() => desactiverSingleFiltre("last_name")}
          />
        )}
        {filtre && filtre.first_name && filtre.first_name.filtered && (
          <Chip
            label="Prénom"
            onDelete={() => desactiverSingleFiltre("first_name")}
          />
        )}
        {filtre && filtre.email && filtre.email.filtered && (
          <Chip
            label="Email"
            onDelete={() => desactiverSingleFiltre("email")}
          />
        )}
        {filtre && filtre.department && filtre.department.filtered && (
          <Chip
            label="Département"
            onDelete={() => desactiverSingleFiltre("department")}
          />
        )}
        <Table
          stickyHeader
          className="min-w-xl"
          aria-labelledby="tableTitle"
          size="small"
        >
          <UserTableHead
            selectedProductIds={selected}
            order={order}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
            setLoading={setLoading}
            filtre={filtre}
            dispatchFiltre={dispatchFiltre}
            setData={setData}
            submitFilter={submitFilter}
            permissions={finalPermissions}
            departments={departments}
          />

          {data.length > 0 && (
            <TableBody>
              {_.orderBy(
                data,
                [
                  (o) => {
                    switch (order.id) {
                      case "categories": {
                        return o.categories[0];
                      }
                      default: {
                        return null;
                      }
                    }
                  },
                ],
                [order.direction]
              ).map((n) => {
                const isSelected = selected.indexOf(n.id) !== -1;
                return (
                  <TableRow
                    className="h-72 cursor-pointer"
                    hover
                    role="checkbox"
                    aria-checked={isSelected}
                    tabIndex={-1}
                    key={`userstable_principale_${n.id}`}
                    selected={isSelected}
                  >
                    <TableCell
                      className={
                        n.active === 0
                          ? "text-red p-4 md:p-16 truncate text-xs"
                          : "p-4 md:p-16 truncate text-xs"
                      }
                      component="th"
                      scope="row"
                    >
                      {n.code}
                    </TableCell>
                    <TableCell
                      className={
                        n.active === 0
                          ? "text-red p-4 md:p-16 text-xs"
                          : "p-4 md:p-16 text-xs"
                      }
                      component="th"
                      scope="row"
                      align="left"
                    >
                      {n.last_name}
                    </TableCell>
                    <TableCell
                      className={
                        n.active === 0
                          ? "text-red p-4 md:p-16 text-xs"
                          : "p-4 md:p-16 text-xs"
                      }
                      component="th"
                      scope="row"
                      align="left"
                    >
                      {n.first_name}
                    </TableCell>
                    <TableCell
                      className={
                        n.active === 0
                          ? "text-red p-4 md:p-16 text-xs"
                          : "p-4 md:p-16 text-xs"
                      }
                      component="th"
                      scope="row"
                      align="left"
                    >
                      {n.email}
                    </TableCell>
                    <TableCell
                      className={
                        n.active === 0
                          ? "text-red p-4 md:p-16 text-xs"
                          : "p-4 md:p-16 text-xs"
                      }
                      component="th"
                      scope="row"
                      align="left"
                    >
                      {n.function}
                    </TableCell>

                    <TableCell
                      className={
                        n.active === 0
                          ? "text-red p-4 md:p-16 text-xs"
                          : "p-4 md:p-16 text-xs"
                      }
                      component="th"
                      scope="row"
                      align="left"
                    >
                      {n.get_department.name}
                    </TableCell>

                    <TableCell
                      className={
                        n.active === 0
                          ? "text-red p-4 md:p-16 text-xs"
                          : "p-4 md:p-16 text-xs"
                      }
                      component="th"
                      scope="row"
                      align="left"
                    >
                      <Icon
                        className="text-green text-20"
                        onClick={(event) => {
                          openSelectedStateMenu(event, n.id, "general");
                        }}
                      >
                        more_horiz
                      </Icon>
                      {selectedStateMenu &&
                        currentSelectedUser === n.id &&
                        createMenu(n.active)}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
      </FuseScrollbars>

      <Stack spacing={2} className="flex flex-row items-center">
        <Pagination
          count={Math.ceil(infoData.total / (filtre.rowsPerPage || 20))}
          page={page}
          onChange={handleChangePage}
          color="secondary"
          showFirstButton
          showLastButton
        />
        <Box sx={{ minWidth: 100 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Résultat par page
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={filtre.rowsPerPage || 20}
              label="Résultat par page"
              onChange={(e) => handleChangeRowsPerPage(e.target.value)}
            >
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={200}>200</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Stack>
      <MaxWidthDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        content={openDialog && ContentModal()}
      />
    </div>
  );
}

export default withRouter(UserTable);
