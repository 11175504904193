const jwtServiceConfig = {
  signIn: "api/login",
  signUp: "api/auth/sign-up",
  accessToken: "api/access-token",
  updateUser: "api/auth/user/update",
  checkEmail: "api/check-email",
  checkTokenAfterSso: "api/check-token-after-sso",
};

export default jwtServiceConfig;
