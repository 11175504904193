import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { getMoodData } from "./DashboardServices";

export default function ChartMood() {
  const [data, setData] = useState();
  const [totalParticipant, setTotalParticipant] = useState(0);
  const [categories, setCategories] = useState([]);
  const [seriesData, setSeriesData] = useState([]);

  useEffect(() => {
    getMoodData().then((res) => {
      setData(res.data.result);
      setTotalParticipant(res.data.totalParticipant);
      const tempOptions = Object.keys(res.data.result);
      tempOptions.sort(function (a, b) {
        return parseInt(a, 10) - parseInt(b, 10);
      });
      setCategories(tempOptions);
      console.log(tempOptions);
      const tempSeries = [];
      const tempSeries2 = [];
      tempOptions.map((o, index) => {
        if (res.data.result[o] !== undefined) {
          tempSeries[index] = res.data.result[o];
        }
        tempSeries2[index] = 3;
      });

      setSeriesData(tempSeries);
    });
  }, []);

  const options = {
    chart: {
      id: "basic-bar",
      stacked: false,
      toolbar: {
        show: true,
      },
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories,
    },
  };
  const series = [
    {
      name: "Score",
      data: seriesData,
    },
  ];
  return (
    <Box className="flex flex-col">
      <Box className="bg-white m-8 p-8 rounded-lg">
        <Typography className="font-bold">
          Nombre de participant:
          <Typography component="span" className="ml-4">
            {totalParticipant}
          </Typography>
        </Typography>
      </Box>
      <Chart options={options} series={series} type="line" height="400" />
    </Box>
  );
}
