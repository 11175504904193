import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  List,
  ListItemText,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import axios from "axios";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import MaxWidthDialog from "src/documentation/material-ui-components/MaxWidthDialog";
import fileDownload from "js-file-download";
import ColoredButton from "app/shared-components/ColoredButton";
import { downloadTemplate } from "./UserServices";

const addFile = (e, files) => {
  if (e.target.files.length > 0) {
    files.current = e.target.files[0];
  }
};

export default function HeaderUser() {
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resultMessage, setResultMessage] = useState({
    success: "",
    error: "",
  });
  const title = "Importer des utilisateurs";
  const usersFile = useRef(undefined);
  const operation = useRef(undefined);
  const save = !!resultMessage.newDepartments;

  useEffect(() => {
    if (openDialog) {
      setResultMessage({
        success: "",
        error: "",
      });
    }
  }, [openDialog]);

  function submitUsersImport() {
    const form = new FormData();
    if (!operation.current) {
      alert("Veuillez choisir le type d'importation");
      return;
    }
    if (!usersFile.current) {
      alert("Veuillez charger un fichier à importer");
      return;
    }

    save;

    form.append("users_file", usersFile.current);
    form.append("operation", operation.current);
    form.append("save", save ? 1 : 0);
    setLoading(true);
    axios
      .post("api/user/import", form, {
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setLoading(false);
        setResultMessage(res.data);
      })
      .catch((err) => {
        setLoading(false);
        setResultMessage((prev) => ({
          ...prev,
          error: "Une erreur s'est produite lors de l'importation",
        }));
      });
  }

  function handleDownloadTemplate() {
    downloadTemplate().then((res) => {
      fileDownload(res.data, `template.xlsx`, "xlsx");
    });
  }

  return (
    <>
      <div className="p-24 sm:p-32 w-full border-b-1 flex justify-between">
        <div className="flex flex-col items-center sm:items-start">
          <Typography
            component={motion.span}
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="text-24 md:text-32 font-extrabold tracking-tight leading-none"
          >
            Utilisateurs
          </Typography>
        </div>
        <Box className="flex gap-8">
          <ColoredButton
            text="Template d'import"
            callback={handleDownloadTemplate}
          />
          <ColoredButton
            text="Importer Utilisateurs"
            callback={() => setOpenDialog(true)}
          />
        </Box>
      </div>

      <MaxWidthDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        title={title}
        content={
          <ImportUsers
            usersFile={usersFile}
            operation={operation}
            submitUsersImport={submitUsersImport}
            loading={loading}
            resultMessage={resultMessage}
            save={save}
          />
        }
      />
    </>
  );
}

function ImportUsers({
  usersFile,
  operation,
  submitUsersImport,
  loading,
  resultMessage,
}) {
  return (
    <Box>
      {resultMessage.success?.length === 0 &&
        resultMessage.error?.length === 0 && (
          <Box className="flex flex-col gap-4 mb-24">
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Importer une liste de collaborateurs
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={(e) => {
                  operation.current = e.target.value;
                }}
              >
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  label="Entrant"
                />
                <FormControlLabel
                  value={2}
                  control={<Radio />}
                  label="Sortant"
                />
              </RadioGroup>
            </FormControl>
            <input
              type="file"
              name="FileAttachment"
              id="FileAttachment"
              className="upload"
              onChange={(e) => addFile(e, usersFile)}
            />
          </Box>
        )}
      {resultMessage.success && (
        <Typography sx={{ fontWeight: "bold", color: "green" }}>
          {resultMessage.success}
        </Typography>
      )}
      {resultMessage.error && (
        <Typography sx={{ fontWeight: "bold", color: "red" }}>
          {resultMessage.error}
        </Typography>
      )}
      {!loading && resultMessage.newDepartments && (
        <>
          <Typography>Nouveaux départements détecté</Typography>
          <List component="nav" aria-label="secondary mailbox folder">
            {resultMessage.newDepartments.map((department) => (
              <ListItemText primary={department.name} />
            ))}
          </List>
        </>
      )}
      {!loading &&
        ((resultMessage.success?.length === 0 &&
          resultMessage.error?.length === 0) ||
          resultMessage.newDepartments) && (
          <ColoredButton
            callback={submitUsersImport}
            text={
              resultMessage.newDepartments
                ? "Confirmer l'importation"
                : "Valider"
            }
          />
        )}
      {loading && <CircularProgress />}
    </Box>
  );
}
