import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import NavLinkAdapter from "@fuse/core/NavLinkAdapter";
import { Box } from "@mui/material";

export default function UserListItem(props) {
  const { user } = props;
  return (
    <>
      <ListItem className="px-32 py-16" sx={{ bgcolor: "background.paper" }}>
        <ListItemAvatar>
          <Avatar />
        </ListItemAvatar>
        <ListItemText
          classes={{ root: "m-0", primary: "font-medium leading-5 truncate" }}
          primary={`${user.first_name} ${user.last_name}`}
          secondary={
            <Box className="flex flex-col gap-4">
              <Typography
                className="inline"
                component="span"
                variant="body2"
                color="text.secondary"
              >
                {user.function}
              </Typography>
              <Typography
                className="inline"
                component="span"
                variant="body2"
                color="text.secondary"
              >
                {user.get_department.name}
              </Typography>
              <Typography
                className="inline"
                component="span"
                variant="body2"
                color="text.secondary"
              >
                {user.email}
              </Typography>
            </Box>
          }
        />
      </ListItem>
      <Divider />
    </>
  );
}
