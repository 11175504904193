import { useMemo, useReducer, useState } from "react";
import FusePageSimple from "@fuse/core/FusePageSimple/FusePageSimple";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import PrivacyPolicyHeader from "./PrivacyPolicyHeader";
import PrivacyPolicyBody from "./PrivacyPolicyBody";

const Root = styled(FusePageSimple)(({ theme }) => ({
  "& .FusePageSimple-header": {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.divider,
  },
  "& .FusePageSimple-toolbar": {},
  "& .FusePageSimple-content": {},
  "& .FusePageSimple-sidebarHeader": {},
  "& .FusePageSimple-sidebarContent": {},
}));

export default function PrivacyPolicyPage() {
  const [data, setData] = useState([]);
  const [openFilters, setOpenFilters] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const value = useMemo(() => {
    return {
      openFilters,
      setOpenFilters,
      data,
      setData,
      rowsPerPage,
      setRowsPerPage,
    };
  }, [data]);

  return (
    <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
      <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
        <Root
          header={<PrivacyPolicyHeader />}
          content={<PrivacyPolicyBody />}
          scroll="content"
        />
      </Box>
    </Box>
  );
}
