import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import _ from "@lodash";
import { useState, useRef, useEffect } from "react";

export default function UpdateUserForm({
  currentUser,
  finalPermissions,
  roles,
  departments,
  updateUser,
}) {
  const lastName = useRef("");
  const firstName = useRef("");
  const emailUser = useRef("");
  const code = useRef("");
  const payCode = useRef("");
  const functionName = useRef("");
  const [role, setRole] = useState(undefined);
  const [department, setDepartment] = useState(undefined);
  const [validating, setValidating] = useState(false);

  function PreUpdateUser() {
    const form = new FormData();
    form.append("id", currentUser.id);
    typeof code.current !== "object" && form.append("code", code.current);
    typeof payCode.current !== "object" &&
      form.append("pay_code", payCode.current);
    typeof firstName.current !== "object" &&
      form.append("first_name", firstName.current);
    typeof lastName.current !== "object" &&
      form.append("last_name", lastName.current);
    typeof emailUser.current !== "object" &&
      form.append("email", emailUser.current);
    typeof functionName.current !== "object" &&
      form.append("function", functionName.current);
    department && form.append("department", department);
    role && form.append("role", role);
    updateUser(form);
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", marginTop: 2 }}>
      <TextField
        ref={code}
        autoComplete="off"
        className="mb-10"
        id="code"
        label="Matricule"
        variant="outlined"
        defaultValue={currentUser && currentUser.code}
        onChange={(e) => {
          code.current = e.target.value;
        }}
      />
      <TextField
        ref={payCode}
        autoComplete="off"
        className="mb-10"
        id="pay_code"
        label="Matricule Paie"
        variant="outlined"
        defaultValue={currentUser && currentUser.pay_code}
        onChange={(e) => {
          payCode.current = e.target.value;
        }}
      />
      <TextField
        ref={lastName}
        autoComplete="off"
        className="mb-10"
        id="nom"
        label="Nom"
        variant="outlined"
        defaultValue={currentUser && currentUser.last_name}
        onChange={(e) => {
          lastName.current = e.target.value;
        }}
      />
      <TextField
        ref={firstName}
        autoComplete="off"
        className="mb-10"
        id="prenom"
        label="Prénom"
        variant="outlined"
        defaultValue={currentUser && currentUser.first_name}
        onChange={(e) => {
          firstName.current = e.target.value;
        }}
      />
      <TextField
        ref={emailUser}
        autoComplete="off"
        className="mb-10"
        id="email"
        label="Email"
        variant="outlined"
        defaultValue={currentUser && currentUser.email}
        onChange={(e) => {
          emailUser.current = e.target.value;
        }}
      />
      <TextField
        ref={functionName}
        autoComplete="off"
        className="mb-10"
        id="function"
        label="Fonction"
        variant="outlined"
        defaultValue={currentUser && currentUser.function}
        onChange={(e) => {
          functionName.current = e.target.value;
        }}
      />
      <FormControl className="mb-10" fullWidth>
        <InputLabel id="client">Département</InputLabel>
        <Select
          labelId="client"
          id="demo-simple-select"
          defaultValue={currentUser && currentUser.department}
          value={department}
          label="Département"
          onChange={(e) => setDepartment(e.target.value)}
        >
          <MenuItem value={-1}>--Département--</MenuItem>
          {departments &&
            departments.map(function (dep) {
              return (
                <MenuItem value={dep.id} key={`department_${dep.id}`}>
                  {dep.name}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
      {_.intersection(finalPermissions, ["canUpdateUserRole"]).length > 0 && (
        <FormControl className="mb-10" fullWidth>
          <InputLabel id="client">Rôle</InputLabel>
          <Select
            labelId="client"
            id="demo-simple-select"
            defaultValue={currentUser && currentUser.role}
            label="Client"
            onChange={(e) => setRole(e.target.value)}
          >
            <MenuItem value={-1}>--Sélectionnez un rôle--</MenuItem>
            {roles &&
              roles.map(function (r) {
                return (
                  <MenuItem value={r.id} key={`userstable_role_${r.id}`}>
                    {r.name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      )}
      {!validating && (
        <div className="mt-10">
          <Button variant="contained" color="success" onClick={PreUpdateUser}>
            Valider
          </Button>
        </div>
      )}
      {validating && <CircularProgress />}
    </Box>
  );
}
