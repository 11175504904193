import MyProfilePage from "./MyProfilePage";

const MyProfileConfig = {
  settings: {
    layout: {
      config: {},
    },
  },

  routes: [
    {
      path: "my-profile",
      element: <MyProfilePage />,
    },
  ],
};

export default MyProfileConfig;
