import {
  Box,
  Checkbox,
  IconButton,
  TableBody,
  TableCell,
  TableRow,
  tableCellClasses,
} from "@mui/material";
import { MONTHS } from "app/configs/globals";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import fileDownload from "js-file-download";
import DeleteIcon from "@mui/icons-material/Delete";
import styled from "styled-components";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { downloadPaySlip } from "../../PaySlipServices";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#000",
    color: "#fff",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "1.2rem",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f5f5f5",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function PaySlipLogTableBody({ rows }) {
  console.log(rows);
  return (
    <TableBody>
      {rows.map((row) => (
        <StyledTableRow key={`log_${row.id}`}>
          <StyledTableCell scope="row">
            {row.created_at?.substring(0, 10)}
          </StyledTableCell>
          <StyledTableCell scope="row">
            {row.state === 0 ? (
              <CancelIcon sx={{ color: "red" }} />
            ) : (
              <CheckCircleIcon sx={{ color: "green" }} />
            )}
          </StyledTableCell>
          <StyledTableCell
            align="left"
            sx={{ color: row.state === 0 ? "red" : "green" }}
          >
            {row.message}
          </StyledTableCell>
        </StyledTableRow>
      ))}
    </TableBody>
  );
}
