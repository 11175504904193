import axios from "axios";

// eslint-disable-next-line import/prefer-default-export
export function getTrombinoscope(filters) {
  let currentFilter = "";
  Object.keys(filters).map((key) => {
    if (filters[key].active) {
      currentFilter +=
        currentFilter.length === 0
          ? `?${key}=${filters[key].value}`
          : `&${key}=${filters[key].value}`;
    }
  });
  currentFilter +=
    currentFilter.length === 0
      ? `?page=${filters.page}`
      : `&page=${filters.page}`;

  return axios.get(`api/trombinoscope${currentFilter}`);
}
