import i18next from "i18next";
import ar from "./navigation-i18n/ar";
import en from "./navigation-i18n/en";
import tr from "./navigation-i18n/tr";

i18next.addResourceBundle("en", "navigation", en);
i18next.addResourceBundle("tr", "navigation", tr);
i18next.addResourceBundle("ar", "navigation", ar);

const navigationConfig = [
  {
    id: "dashboard",
    title: "Dashboard",
    translate: "DASHBOARD",
    type: "item",
    icon: "heroicons-outline:star",
    url: "dashboard",
    permissions: ["canReadDashboard"],
  },
  {
    id: "rh",
    title: "Rh",
    translate: "HUMAN_RESSOURCE",
    type: "collapse",
    icon: "heroicons-outline:star",
    permissions: ["canReadUsers", "canReadTrombinoscope", "canReadPaySlips"],
    children: [
      {
        id: "trombinoscope",
        title: "Trombinoscope",
        translate: "TROMBINOSCOPE",
        type: "item",
        url: "hr/trombinoscope",
        permissions: ["canReadTrombinoscope"],
      },
      {
        id: "show-users",
        title: "Show Users",
        translate: "SHOW_USERS",
        type: "item",
        url: "hr/users",
        permissions: ["canReadUsers"],
      },
      {
        id: "payslips",
        title: "PaySlips",
        translate: "PAYSLIPS",
        type: "item",
        url: "hr/payslips",
        permissions: ["canReadPaySlips"],
      },
    ],
  },
  {
    id: "mydocuments",
    title: "My Documents",
    translate: "MYDOCUMENTS",
    type: "collapse",
    icon: "heroicons-outline:star",
    permissions: ["canReadMyPaySlips"],
    children: [
      {
        id: "mypayslips",
        title: "Mes Bulletins de paie",
        translate: "MYPAYSLIPS",
        type: "item",
        url: "mypayslips",
        permissions: ["canReadMyPaySlips"],
      },
    ],
  },
  {
    id: "setting",
    title: "Settings",
    translate: "SETTINGS",
    type: "collapse",
    icon: "heroicons-outline:star",
    permissions: ["canReadSettings"],
    children: [
      {
        id: "access",
        title: "Access Management",
        translate: "Access",
        type: "item",
        url: "settings/access-management",
        permissions: ["canReadSettings"],
      },
      {
        id: "privacy-policy",
        title: "Privacy Policy",
        translate: "PRIVACY_POLICY",
        type: "item",
        url: "settings/privacy-policy",
        permissions: ["canReadSettings"],
      },
      {
        id: "terms-of-use",
        title: "Terms og use",
        translate: "TERMS_OF_USE",
        type: "item",
        url: "settings/terms-of-use",
        permissions: ["canReadSettings"],
      },
      {
        id: "department",
        title: "Département",
        translate: "DEPARTMENT",
        type: "item",
        url: "settings/department",
        permissions: ["canReadSettings"],
      },
      {
        id: "rules-of-procedure",
        title: "Règlement intérieur",
        translate: "RULES_OF_PROCEDURE",
        type: "item",
        url: "settings/rules-of-procedure",
        permissions: ["canReadSettings"],
      },
    ],
  },
];

export default navigationConfig;
