import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Snackbar,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";
import updatePermission from "../PermissionServices";

export default function PermissionList({ role, setRefreshRole }) {
  const [checkedPermissions, setCheckedPermissions] = useState([]);
  const [loading, setLoading] = useState();
  const [openSnackBar, setOpenSnackBar] = useState(false);

  useEffect(() => {
    const temp = [...checkedPermissions];
    role.get_permissions.map((permission) => {
      if (permission.active_permission === 1) {
        temp.push(permission.id);
      }
    });

    setCheckedPermissions(temp);
  }, []);

  function handleChangePermission() {
    setLoading(true);
    updatePermission(role.id, checkedPermissions)
      .then(() => {
        setOpenSnackBar(true);
        setRefreshRole((prev) => !prev);
      })
      .finally(() => setLoading(false));
  }

  function changePermission(event) {
    if (event.target.checked) {
      if (checkedPermissions.indexOf(parseInt(event.target.value, 10)) === -1) {
        setCheckedPermissions((prev) => [
          ...prev,
          parseInt(event.target.value, 10),
        ]);
      }
    } else {
      const temp = [...checkedPermissions];
      const index = temp.indexOf(parseInt(event.target.value, 10));
      temp.splice(index, 1);
      setCheckedPermissions(temp);
    }
  }

  return (
    <>
      <Box className="flex flex-col">
        <Box>
          {role &&
            role.get_permissions.map((permission) => {
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      value={permission.id}
                      onChange={changePermission}
                      defaultChecked={permission.active_permission === 1}
                    />
                  }
                  label={permission.fr}
                />
              );
            })}
        </Box>
        {!loading && (
          <Button variant="contained" onClick={handleChangePermission}>
            Valider
          </Button>
        )}
      </Box>

      {loading && <CircularProgress />}
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={openSnackBar}
          autoHideDuration={6000}
          onClose={() => setOpenSnackBar(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={() => setOpenSnackBar(false)}
            severity="success"
            sx={{ width: "100%" }}
          >
            Permissions modifié avec succés!
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
}
