import { styled } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";
import { useSelector } from "react-redux";
import { selectUser } from "app/store/userSlice";

export default function ColoredButton({ callback, sx, text }) {
  const user = useSelector(selectUser);
  const company = user.get_company;
  const customStyle = {
    ...sx,
    backgroundColor: company.color3,
    color: "#fff",
    "&:hover": {
      color: "#fff",
      background: company.color1,
    },
  };

  return (
    <Button onClick={callback} sx={customStyle}>
      {text}
    </Button>
  );
}
